import { CourseCmsDto, CourseModuleCmsDto } from '@ac/models';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, DestroyRef, inject, OnDestroy } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { map, Observable, switchMap, tap } from 'rxjs';
import { CmsClient } from '../../ngrx-data/cms-client.service';
import { filter } from 'rxjs/operators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-courses-edit',
  templateUrl: './courses-edit.component.html',
  styleUrls: ['./courses-edit.component.scss']
})
export class CoursesEditComponent {
  #destroyRef = inject(DestroyRef);
  #cmsClient = inject(CmsClient);

  protected pageTitle = 'Neuen Kurs anlegen';
  protected backToListPath$ = this.#cmsClient.courseContext$.pipe(
    map(context => (context === 'CourseCMS' ? '/cyber-portal/inhaltspflege' : '/cyber-portal/inhaltspflege/cms/company-courses'))
  );

  course$: Observable<CourseCmsDto>;
  course: CourseCmsDto;
  formGroup: UntypedFormGroup;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    this.course$ = activatedRoute.params.pipe(
      switchMap(params => this.#cmsClient.entityMap$().pipe(map(entities => entities[params.courseId]))),
      tap(course => (this.course = { ...course })),
      tap(course => (this.pageTitle = course?.title || 'Neuen Kurs anlegen')),
      tap(entity => this.initializeForm(entity))
    );
  }

  async addModule(): Promise<void> {
    const module: CourseModuleCmsDto = {
      lessons: [],
      title: '',
      duration: 0,
      id: crypto.randomUUID()
    };

    this.course.modules = [...this.course.modules, module];

    this.#cmsClient.collectionEntityClient().updateOneInCache(this.course);

    await this.router.navigate(['..', 'modules', module.id, 'edit'], {
      relativeTo: this.activatedRoute
    });
  }

  removeModule(ModuleId: string): void {
    this.course.modules = this.course.modules.filter(module => module.id !== ModuleId);
    this.#cmsClient.collectionEntityClient().updateOneInCache(this.course);
  }

  initializeForm(entity: CourseCmsDto): void {
    if (entity) {
      this.formGroup = new UntypedFormGroup({
        title: new UntypedFormControl(entity.title, Validators.required),
        abstract: new UntypedFormControl(entity.abstract, Validators.required),
        imageUrl: new UntypedFormControl(entity.imageUrl),
        description: new UntypedFormControl(entity.description),
        numberOfTasks: new UntypedFormControl(entity.numberOfTasks),
        requiredNumberOfCorrectAnswers: new UntypedFormControl(entity.requiredNumberOfCorrectAnswers),
        hasExam: new UntypedFormControl(entity.hasExam)
      });

      this.formGroup.valueChanges
        .pipe(
          filter(() => this.formGroup.valid),
          tap(values => {
            this.#cmsClient.collectionEntityClient().updateOneInCache({
              ...this.course,
              ...values
            });
          }),
          takeUntilDestroyed(this.#destroyRef)
        )
        .subscribe();
    }
  }

  drop(event: CdkDragDrop<CourseModuleCmsDto[]>) {
    const tmpModules = [...this.course.modules];

    moveItemInArray(tmpModules, event.previousIndex, event.currentIndex);
    this.course.modules = tmpModules;
    this.#cmsClient.collectionEntityClient().updateOneInCache(this.course);
  }
}
