import { Injectable } from '@angular/core';
import { environment } from '@env';

@Injectable({ providedIn: 'root' })
export class UrlBuilder {
  environment = environment;

  goToCourseOverview(): string {
    return '/cyber-portal/course-overview';
  }

  goToCourse(courseId: string): string {
    return `/cyber-portal/courses/${courseId}`;
  }

  goToCompanyCourse(companyId: string, courseId: string): string {
    return `/cyber-portal/companies/${companyId}/courses/${courseId}`;
  }

  goToVideo(courseId: string, contentId: string): string {
    return `/cyber-portal/courses/${courseId}/player/${contentId}`;
  }

  goToCompanyVideo(companyId: string, courseId: string, contentId: string): string {
    return `/cyber-portal/companies/${companyId}/courses/${courseId}/player/${contentId}`;
  }

  goToLogin(): string {
    return '/cyber-portal/login';
  }

  goToLogout(): string {
    return '/cyber-portal/logout';
  }

  goToHome(): string {
    return '';
  }

  goToUserManagement(): string {
    return '/cyber-portal/manage-users';
  }

  goToPasswordReset(): string {
    return '/cyber-portal/reset-password';
  }

  goToPhishingMails(): string {
    return '/cyber-portal/phishing-mails';
  }

  goToPhishingMailsUser(userId?: string): string {
    return `${this.goToPhishingMails()}/users/${userId || ''}`;
  }

  goToPhishingMailsCompany(companyId?: string): string {
    return `${this.goToPhishingMails()}/companies/${companyId || ''}`;
  }

  downloadCertificate(userId: string, courseId: string): string {
    return environment.apiUrl + environment.apiRoutes.certificates + `?userId=${userId}&courseId=${courseId}`;
  }

  goToEmailCourse(): string {
    return '/cyber-portal/courses/626970b6-9abf-47ed-b0f8-398df94fbd45';
  }

  goToUserEdit(): string {
    return '/cyber-portal/edit-user';
  }

  getImageVariation(imageUrl: string, variation: 'card' | 'banner'): string {
    const imageName = imageUrl.split('/').pop();
    const newImageName = imageName.replace('.', `_${variation}.`);

    return imageUrl.replace(imageName, newImageName);
  }

  goToHelp(): string {
    return '/cyber-portal/help';
  }

  goToAdministration(): string {
    return '/cyber-portal/administration';
  }

  goToToolkit(): string {
    return '/cyber-portal/toolkit';
  }

  goToFeedbackDetails(id: string) {
    return '/cyber-portal/feedbacks/' + id;
  }

  goToCompanyEdit() {
    return '/cyber-portal/edit-company';
  }

  goToSubscriptionOverview() {
    return '/cyber-portal/subscription';
  }

  goToCms() {
    return '/cyber-portal/inhaltspflege';
  }
}
