import { Overlay } from '@angular/cdk/overlay';
import { Component, Input } from '@angular/core';
import { environment } from '../../../../../apps/app/src/environments/environment';

@Component({
  selector: 'app-info-box',
  templateUrl: './info-box.component.html',
  styleUrls: ['./info-box.component.scss']
})
export class InfoBoxComponent {
  environment = environment;
  @Input() description: string;
  @Input() gdpr: string;
  isOpen = false;

  constructor(public overlay: Overlay) {}
}
