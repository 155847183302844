import { SaveFeedbackDto } from '@ac/models';
import { Component, effect, inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BrowserInfoService } from '../browser-info.service';
import { IdentityService } from '../identity/identity.service';
import { FeedbackApiService } from './feedback-api.service';
import { tap } from 'rxjs/operators';
import moment from 'moment';
import { ContactWindowState } from './contact-window-state.service';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit {
  #state = inject(ContactWindowState);

  protected isOpen = this.#state.isOpen;

  feedbackForm: UntypedFormGroup;
  subjects: string[] = ['Fehler melden', 'Frage stellen', 'Idee vorschlagen', 'Feedback geben', 'Paket-Upgrade'];

  constructor(
    private browserInfo: BrowserInfoService,
    public identityService: IdentityService,
    private formBuilder: UntypedFormBuilder,
    private feedbackApi: FeedbackApiService
  ) {
    this.feedbackForm = this.formBuilder.group({
      subject: ['', [Validators.required]],
      message: ['', [Validators.required, Validators.maxLength(1000)]]
    });

    effect(() => this.#patchFormOnStateChange());
  }

  ngOnInit(): void {
    this.browserInfo.getBrowserInfo();
  }

  toggleMenu() {
    if (this.#state.isOpen()) this.#state.close();
    else this.#state.open();
  }

  onSubmit(feedbackForm: UntypedFormGroup, event: Event) {
    event.preventDefault();

    if (feedbackForm.invalid) {
      return;
    }

    const browserInfo = this.browserInfo.getBrowserInfo();

    const dto: SaveFeedbackDto = {
      browserLanguage: browserInfo.browserLanguage,
      browserName: browserInfo.browserName,
      browserVersion: browserInfo.browserVersion,
      currentPath: window.location.href,
      dateTime: moment().toISOString(true),
      nativeScreenHeight: Math.round(browserInfo.nativeScreenHeight),
      nativeScreenWidth: Math.round(browserInfo.nativeScreenWidth),
      operatingSystem: browserInfo.operatingSystem.toString(),
      screenHeight: Math.round(browserInfo.screenHeight),
      screenWidth: Math.round(browserInfo.screenWidth),
      message: feedbackForm.get('message').value,
      subject: feedbackForm.get('subject').value
    };

    this.feedbackApi
      .sendFeedback(dto)
      .pipe(
        tap(() => {
          this.feedbackForm.reset();
          //After resetting all required errors are present: https://github.com/angular/components/issues/4190
          Object.keys(this.feedbackForm.controls).forEach(key => {
            this.feedbackForm.controls[key].setErrors(null);
          });
          this.toggleMenu();
        })
      )
      .subscribe();
  }

  #patchFormOnStateChange() {
    const formValues = this.#state.formValues();

    this.feedbackForm.patchValue(formValues);
  }
}
