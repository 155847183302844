import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class SelectOptionsService {
  departments(): string[] {
    const departments = [
      'Geschäftsführung',
      'Personalabteilung',
      'Finanzen',
      'Rechnungswesen',
      'Rechtsabteilung',
      'Fertigung, Produktion',
      'Qualitätssicherung',
      'Marketingabteilung',
      'Arbeitssicherheit',
      'IT',
      'Einkauf',
      'Verkauf, Vertrieb',
      'Logistik',
      'Poststelle',
      'Kundenbetreuung',
      'Hausverwaltung',
      'Forschung',
      'Entwicklung',
      'Sonstiges',
      'Auszubildende'
    ];

    return departments.sort();
  }

  businessSectors(): string[] {
    const businessSectors = [
      'Automobilindustrie',
      'Baugewerbe',
      'Beauty',
      'Bildung',
      'Biotechnologie',
      'Chemie',
      'Finanzen',
      'Versicherung',
      'Dienstleistungen',
      'Energie',
      'Elektronik',
      'Freizeit',
      'Gastronomie',
      'Gesundheitswesen',
      'Handel',
      'Handwerk',
      'Hotelgewerbe',
      'Immobilien',
      'Informationstechnologie',
      'Unterhaltung',
      'Land- und Forstwirtschaft',
      'Lebensmittelindustrie',
      'Logistik',
      'Luft- und Raumfahrt',
      'Maschinenbau',
      'Medizintechnik',
      'Metallindustrie',
      'Papierindustrie',
      'Pharmazie',
      'Spielzeug',
      'Sport',
      'Schiffbau',
      'Telekommunikation',
      'Textil- und Bekleidungs',
      'Tourismus',
      'Wasser und Abwasser',
      'Entsorgung',
      'Sonstiges'
    ];

    return businessSectors.sort();
  }
}
