import {
  CourseCmsDto,
  CourseLessonCmsDto,
  CourseModuleCmsDto,
  CourseResourceCmsDto,
  LessonCaptionCmsDto
} from '@ac/models';
import { Component, DestroyRef, inject, signal } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { replaceItemInArray } from '../../lib/array-operations';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CmsClient } from '../../ngrx-data/cms-client.service';

@Component({
  selector: 'app-lesson-edit',
  templateUrl: './lesson-edit.component.html',
  styleUrls: ['./lesson-edit.component.scss']
})
export class LessonEditComponent {
  #destroyRef = inject(DestroyRef);
  #cmsClient = inject(CmsClient);

  private lesson: CourseLessonCmsDto;

  lesson$: Observable<CourseLessonCmsDto>;

  formGroup: UntypedFormGroup;
  course: CourseCmsDto;
  module: CourseModuleCmsDto;

  protected moduleUrl = signal<string>('');

  constructor(private ac: ActivatedRoute) {
    this.lesson$ = this.ac.params.pipe(
      switchMap(params =>
        this.#cmsClient.entityMap$().pipe(
          map(entities => {
            this.course = { ...entities[params.courseId] };
            return entities[params.courseId];
          }),
          map(course => {
            const module = course?.modules.find(moduleCandidate => moduleCandidate.id === params.moduleId);
            this.module = { ...module };

            if (course) {
              this.moduleUrl.set(`/cyber-portal/inhaltspflege/cms/courses/${course.id}/modules/${module.id}/edit`);
            }

            return this.module;
          }),
          map(module => (module?.lessons || []).find(video => video.id === params.lessonId))
        )
      ),
      tap(lesson => (this.lesson = lesson)),
      tap(lesson => this.initializeForm(lesson))
    );
  }

  resourcesFormGroup(value: Partial<CourseResourceCmsDto>): UntypedFormGroup {
    return new UntypedFormGroup(
      {
        id: new UntypedFormControl(value.id || crypto.randomUUID()),
        url: new UntypedFormControl(value.url || '', Validators.required),
        name: new UntypedFormControl(value.name || '', Validators.required),
        description: new UntypedFormControl(value.description || '', Validators.required)
      },
      { updateOn: 'blur' }
    );
  }

  captionsFormGroup(value: Partial<LessonCaptionCmsDto>): UntypedFormGroup {
    return new UntypedFormGroup(
      {
        source: new UntypedFormControl(value.source || '', Validators.required),
        label: new UntypedFormControl(value.label || '', Validators.required),
        language: new UntypedFormControl(value.language || '', Validators.required)
      },
      { updateOn: 'blur' }
    );
  }

  addResource(): void {
    (this.formGroup.controls.resources as UntypedFormArray).push(this.resourcesFormGroup({}));
  }

  removeResource(index: number): void {
    (this.formGroup.controls.resources as UntypedFormArray).removeAt(index);
  }

  addCaption(): void {
    (this.formGroup.controls.captions as UntypedFormArray).push(this.captionsFormGroup({}));
  }

  removeCaption(index: number): void {
    (this.formGroup.controls.captions as UntypedFormArray).removeAt(index);
  }

  initializeForm(entity: CourseLessonCmsDto): void {
    if (entity) {
      this.formGroup = new UntypedFormGroup({
        title: new UntypedFormControl(entity.title, {
          updateOn: 'blur',
          validators: Validators.required
        }),
        streamUrl: new UntypedFormControl(entity.streamUrl, {
          updateOn: 'blur',
          validators: Validators.required
        }),
        duration: new UntypedFormControl(entity.duration, {
          updateOn: 'blur',
          validators: Validators.required
        }),
        resources: new UntypedFormArray(entity.resources.map(resource => this.resourcesFormGroup(resource))),
        captions: new UntypedFormArray(entity.captions.map(resource => this.captionsFormGroup(resource)))
      });

      this.formGroup.valueChanges
        .pipe(
          tap(values => {
            if (this.formGroup.valid) {
              this.module.lessons = replaceItemInArray(this.module.lessons, {
                ...this.lesson,
                ...values
              });
              this.course.modules = replaceItemInArray(this.course.modules, this.module);
              this.#cmsClient.collectionEntityClient().updateOneInCache(this.course);
            }
          }),
          takeUntilDestroyed(this.#destroyRef)
        )
        .subscribe();
    }
  }
}
