import { Directive, effect, inject, input, TemplateRef, ViewContainerRef } from '@angular/core';

import { UserRole } from './user-role.type';
import { IdentityService } from './identity.service';

@Directive({ selector: '[hasRole]', standalone: true })
export class HasRoleDirective {
  #identityService = inject(IdentityService);

  #templateRef = inject(TemplateRef<unknown>);
  #viewContainer = inject(ViewContainerRef);

  roles = input.required<UserRole | UserRole[]>({ alias: 'hasRole' });

  constructor() {
    effect(() => this.#showOrHideTemplateDependingOnRole());
  }

  #showOrHideTemplateDependingOnRole() {
    const hasRole = this.#identityService.hasAnyRole(this.roles());

    if (hasRole) {
      this.#addTemplate();
    } else {
      this.#clearTemplate();
    }
  }

  #addTemplate() {
    this.#viewContainer.clear();
    this.#viewContainer.createEmbeddedView(this.#templateRef);
  }

  #clearTemplate() {
    this.#viewContainer.clear();
  }
}
