import {Component, input} from '@angular/core';
import {animationFrameScheduler, combineLatest, distinctUntilChanged, endWith, interval, map, switchMap} from "rxjs";
import {takeWhile} from "rxjs/operators";
import {toObservable} from "@angular/core/rxjs-interop";

@Component({
  selector: 'ac-number-animation',
  templateUrl: './number-animation.component.html',
  styleUrls: ['./number-animation.component.scss']
})
export class NumberAnimationComponent {
  number = input.required<number>();
  duration = input<number>(1000);

  protected readonly currentCount$ = combineLatest([
    toObservable(this.number),
    toObservable(this.duration),
  ]).pipe(
      switchMap(([count, duration]) => {
        // get the time when animation is triggered
        const startTime = animationFrameScheduler.now();

        return interval(0, animationFrameScheduler).pipe(
            // calculate elapsed time
            map(() => animationFrameScheduler.now() - startTime),
            // calculate progress
            map((elapsedTime) => elapsedTime / duration),
            // complete when progress is greater than 1
            takeWhile((progress) => progress <= 1),
            // apply quadratic ease-out function
            // for faster start and slower end of counting
            map(easeOutQuad),
            // calculate current count
            map((progress) => Math.round(progress * count)),
            // make sure that last emitted value is count
            endWith(count),
            distinctUntilChanged()
        );
      }),
  );
}

/**
 * Quadratic Ease-Out Function: f(x) = x * (2 - x)
 */
const easeOutQuad = (x: number): number => x * (2 - x);
