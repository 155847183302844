import { Component } from '@angular/core';

@Component({
  selector: 'ac-box-centered',
  template: `
    <ng-content></ng-content>
  `,
  styles: [
    `
      :host {
        display: block;
        max-width: 1300px;
        margin: 0 auto 0 auto;
        padding: 1.5rem 2rem 1.5rem 2rem;
      }
    `
  ]
})
export class BoxCenteredComponent {}
