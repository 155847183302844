import { CourseCmsDto, CourseLessonCmsDto, CourseModuleCmsDto } from '@ac/models';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, DestroyRef, inject, Input, signal } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { replaceItemInArray } from '../../lib/array-operations';
import { CmsClient } from '../../ngrx-data/cms-client.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-module-edit',
  templateUrl: './module-edit.component.html',
  styleUrls: ['./module-edit.component.scss']
})
export class ModuleEditComponent {
  #destroyRef = inject(DestroyRef);
  #cmsClient = inject(CmsClient);

  private module: CourseModuleCmsDto;

  @Input() module$: Observable<CourseModuleCmsDto>;

  formGroup: UntypedFormGroup;
  course: CourseCmsDto;

  courseUrl = signal('/');

  constructor(
    private ac: ActivatedRoute,
    private router: Router
  ) {
    this.module$ = ac.params.pipe(
      switchMap(params =>
        this.#cmsClient.entityMap$().pipe(
          map(entities => {
            this.course = { ...entities[params.courseId] };
            return entities[params.courseId];
          }),
          tap(course => (course?.id ? this.courseUrl.set(`/cyber-portal/inhaltspflege/cms/courses/${course.id}/edit`) : '/')),
          map(course => course?.modules.find(module => module.id === params.moduleId))
        )
      ),
      tap(module => (this.module = { ...module })),
      tap(module => this.initializeForm(module))
    );
  }

  removeModule(moduleId: string): void {
    this.course.modules = this.course.modules.filter(module => module.id !== moduleId);
  }

  addLesson(): void {
    const lesson: CourseLessonCmsDto = {
      title: '',
      streamUrl: '',
      duration: 0,
      id: crypto.randomUUID(),
      captions: [],
      resources: []
    };

    this.module.lessons = [...this.module.lessons, lesson];
    this.course.modules = replaceItemInArray(this.course.modules, this.module);
    this.#cmsClient.collectionEntityClient().updateOneInCache(this.course);
    this.router.navigate(['..', 'lessons', lesson.id, 'edit'], {
      relativeTo: this.ac
    });
  }

  removeLesson(lessonId: string): void {
    this.module.lessons = this.module.lessons.filter(lesson => lesson.id !== lessonId);
    this.course.modules = [...this.course.modules.filter(module => module.id !== this.module.id), this.module];
    this.#cmsClient.collectionEntityClient().updateOneInCache(this.course);
  }

  initializeForm(entity: CourseModuleCmsDto): void {
    if (entity) {
      this.formGroup = new UntypedFormGroup(
        {
          title: new UntypedFormControl(entity.title, Validators.required)
        },
        { updateOn: 'blur' }
      );

      this.formGroup.valueChanges
        .pipe(
          tap(values => {
            if (this.formGroup.valid) {
              const module = {
                ...this.course.modules.find(m => m.id === this.module.id),
                ...values
              };

              this.course.modules = replaceItemInArray(this.course.modules, module);
              this.#cmsClient.collectionEntityClient().updateOneInCache(this.course);
            }
          }),
          takeUntilDestroyed(this.#destroyRef)
        )
        .subscribe();
    }
  }

  drop(event: CdkDragDrop<CourseCmsDto[]>) {
    const tmpLessons = [...this.module.lessons];

    moveItemInArray(tmpLessons, event.previousIndex, event.currentIndex);
    this.module.lessons = tmpLessons;
    this.course.modules = replaceItemInArray(this.course.modules, this.module);
    this.#cmsClient.collectionEntityClient().updateOneInCache(this.course);
  }
}
