import { PasswordPolicyDto } from '@ac/models';
import { AbstractControl } from '@angular/forms';
import { PasswordValidationErrors } from './password-validation-errors';

export class PasswordValidators {
  //todo: pass password control names
  static equalPasswords(group: AbstractControl) {
    if (group.get('password').value !== group.get('password2').value) {
      group.get('password').setErrors({ notEqual: true });
      group.get('password2').setErrors({ notEqual: true });
      return { notEqual: true };
    }
    group.get('password').setErrors(null);
    group.get('password2').setErrors(null);
    return null;
  }

  static policy(policy: PasswordPolicyDto) {
    return (control: AbstractControl) => {
      const errors: PasswordValidationErrors = [];

      if (policy.requireDigit) {
        if (!this.passwordContainsDigit(control.value)) {
          errors.push('containsDigits');
        }
      }

      if (policy.requireLowercase) {
        if (!this.passwordContainsLowercase(control.value)) {
          errors.push('containsLowercase');
        }
      }

      if (policy.requireUppercase) {
        if (!this.passwordContainsUppercase(control.value)) {
          errors.push('containsUppercase');
        }
      }

      if (policy.requireNonAlphanumeric) {
        if (!this.passwordContainsSpecialCharacter(control.value)) {
          errors.push('containsSpecialCharacter');
        }
      }

      if (!this.passwordHasRequiredCountOfUniqueChars(control.value, policy.requiredUniqueChars)) {
        errors.push('hasRequiredCountOfUniqueChars');
      }

      if (!this.passwordHasRequiredMinimalLength(control.value, policy.requiredLength)) {
        errors.push('hasRequiredMinimalLength');
      }

      if (errors.length === 0) {
        return null;
      }

      return errors.reduce(
        (errorDictionary, error) => ({
          ...errorDictionary,
          [error]: true
        }),
        {}
      );
    };
  }

  private static passwordContainsDigit(password: string): boolean {
    return /\d/.test(password);
  }

  private static passwordContainsUppercase(password: string): boolean {
    return /[A-Z]/.test(password);
  }

  private static passwordContainsLowercase(password: string): boolean {
    return /[a-z]/.test(password);
  }

  private static passwordContainsSpecialCharacter(password: string): boolean {
    return /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(password);
  }

  private static passwordHasRequiredMinimalLength(password: string, requiredLength: number): boolean {
    return password.length >= requiredLength;
  }

  private static passwordHasRequiredCountOfUniqueChars(password: string, requiredUniqueChars: number): boolean {
    return String.prototype.concat(...new Set(password)).length >= requiredUniqueChars;
  }
}
