import { ChangeDetectionStrategy, Component, computed, effect, input, model, signal } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { distinctUntilChanged } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DateRange } from './date-range';
import { Preset } from './preset';

@Component({
  selector: 'date-range-input',
  templateUrl: './date-range-input.component.html',
  styleUrl: './date-range-input.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DateRangeInputComponent {
  min = input<Date | null>(null);
  max = input<Date | null>(null);
  range = model<DateRange>({ from: new Date(), to: new Date() });
  label = input<string>('Zeitspanne eingeben');
  hint = input<string>('');
  presets = input<Preset[]>([]);
  disabled = input(false);
  isOverlayOpen = signal(false);
  formattedRange = computed(() =>
    this.range()
      ? this.range().from.toLocaleDateString('de-DE', {
          day: 'numeric',
          month: 'short',
          year: 'numeric'
        }) +
        ' – ' +
        this.range().to.toLocaleDateString('de-DE', { day: 'numeric', month: 'short', year: 'numeric' })
      : '-'
  );

  rangeFormGroup = new FormGroup({
    from: new FormControl<Date | null>({ value: this.range()?.from, disabled: this.disabled() }),
    to: new FormControl<Date | null>({ value: this.range()?.to, disabled: this.disabled() })
  });

  constructor() {
    effect(() => {
      if (this.disabled()) {
        this.rangeFormGroup.disable();
      } else {
        this.rangeFormGroup.enable();
      }
    });

    toObservable(this.range)
      .pipe(
        takeUntilDestroyed(),
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
        tap(range => this.rangeFormGroup.patchValue(range as DateRange))
      )
      .subscribe();
  }

  setPreset(preset: Preset) {
    const range = {
      from: new Date(preset.range.from),
      to: new Date(preset.range.to)
    };

    this.range.set(range);

    this.isOverlayOpen.set(false);
  }

  openOverlay() {
    if (this.disabled()) {
      return;
    }

    this.isOverlayOpen.set(true);
  }

  closeOverlay() {
    this.isOverlayOpen.set(false);
  }

  setCustomRange() {
    if (this.rangeFormGroup.invalid || this.disabled()) return;

    const fromForm = this.rangeFormGroup.get('from')?.value;
    const toForm = this.rangeFormGroup.get('to')?.value;

    if (fromForm == null || toForm == null) return;

    const range = {
      from: new Date(fromForm),
      to: new Date(toForm)
    };

    const min = this.min();

    if (min) {
      range.from = range.from >= min ? range.from : min;
    }

    const max = this.max();

    if (max) {
      range.to = range.to <= max ? range.to : max;
    }

    this.range.set(range);

    this.isOverlayOpen.set(false);
  }
}
