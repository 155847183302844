import { WvHeroBannerComponent } from './wv-hero-banner/wv-hero-banner.component';
import {OverlayModule} from '@angular/cdk/overlay';
import {CommonModule} from '@angular/common';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {MatLegacyCardModule as MatCardModule} from '@angular/material/legacy-card';
import {MatLegacyFormFieldModule as MatFormFieldModule} from '@angular/material/legacy-form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatLegacyInputModule as MatInputModule} from '@angular/material/legacy-input';
import {MatLegacyProgressSpinnerModule as MatProgressSpinnerModule} from '@angular/material/legacy-progress-spinner';
import {NgCircleProgressModule} from 'ng-circle-progress';
import {LazyLoadImageModule} from 'ng-lazyload-image';
import {RenderHTMLPipe} from './render-html.pipe';
import {BackArrowButtonComponent} from './back-arrow-button/back-arrow-button.component';
import {BoxCenteredComponent} from './box-centered/box-centered.component';
import {CardComponent} from './card/card.component';
import {DisableOnClickDirective} from './directives/disable-on-click.directive';
import {DurationPipe} from './duration.pipe';
import {ImageFadedComponent} from './image-faded/image-faded.component';
import {InfoBoxComponent} from './info-box/info-box.component';
import {NumberAnimationComponent} from './number-animation/number-animation.component';
import {PasswordCheckComponent} from './password-check/password-check.component';
import {PercentageAnimationComponent} from './percentage-animation/percentage-animation.component';
import {ResourceCardComponent} from './resource-card/resource-card.component';
import {StatisticCardComponent} from './statistic-card/statistic-card.component';
import {TrendComponent} from './trend/trend.component';
import {WvButtonComponent} from './wv-button/wv-button.component';
import {WvChevronLinkComponent} from './wv-chevron-link/wv-chevron-link.component';
import {PlainStepperComponent} from './plain-stepper/plain-stepper.component';
import {CdkStepperModule} from '@angular/cdk/stepper';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {RecaptchaMustBeVisibleDirective} from './recaptcha-must-be-visible.directive';
import {ReactiveFormsModule} from '@angular/forms';
import {TranslocoDirective, TranslocoPipe} from '@ngneat/transloco';
import {RouterModule} from '@angular/router';
import {VideoplayerComponent} from './videoplayer/videoplayer.component';
import {DateRangeInputComponent} from './date-range-input/date-range-input.component';
import {MatDatepickerModule} from "@angular/material/datepicker";
import {NeedsPackageUpgradeDirective} from "@app/subscriptions/needs-package-upgrade.directive";

@NgModule({
  declarations: [
    BackArrowButtonComponent,
    BoxCenteredComponent,
    CardComponent,
    DisableOnClickDirective,
    DurationPipe,
    ImageFadedComponent,
    RenderHTMLPipe,
    PasswordCheckComponent,
    PercentageAnimationComponent,
    WvButtonComponent,
    StatisticCardComponent,
    NumberAnimationComponent,
    TrendComponent,
    WvChevronLinkComponent,
    ResourceCardComponent,
    InfoBoxComponent,
    PlainStepperComponent,
    WvHeroBannerComponent,
    RecaptchaMustBeVisibleDirective,
    VideoplayerComponent,
    DateRangeInputComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    RouterModule,
    TranslocoPipe,
    CommonModule,
    MatIconModule,
    MatProgressSpinnerModule,
    NgCircleProgressModule,
    LazyLoadImageModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    OverlayModule,
    CdkStepperModule,
    MatButtonModule,
    MatDatepickerModule,
    NeedsPackageUpgradeDirective,
    TranslocoDirective
  ],
  exports: [
    BackArrowButtonComponent,
    BoxCenteredComponent,
    CardComponent,
    DisableOnClickDirective,
    DurationPipe,
    RenderHTMLPipe,
    ImageFadedComponent,
    PasswordCheckComponent,
    PercentageAnimationComponent,
    WvButtonComponent,
    StatisticCardComponent,
    NumberAnimationComponent,
    TrendComponent,
    WvChevronLinkComponent,
    ResourceCardComponent,
    InfoBoxComponent,
    PlainStepperComponent,
    WvHeroBannerComponent,
    RecaptchaMustBeVisibleDirective,
    VideoplayerComponent,
    DateRangeInputComponent
  ]
})
export class ColibriModule {}
