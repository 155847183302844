import { FeedbackDto, SaveFeedbackDto } from '@ac/models';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '@env';
import { LoadingDialogState } from '../layouts/loading-dialog/loading-dialog-state.enum';
import { LoadingDialogComponent } from '../layouts/loading-dialog/loading-dialog.component';
import { LoadingDialogService } from '../layouts/loading-dialog/loading-dialog.service';

@Injectable({ providedIn: 'root' })
export class FeedbackApiService {
  constructor(
    private loadingDialog: LoadingDialogService,
    private http: HttpClient
  ) {}

  sendFeedback(feedbackDto: SaveFeedbackDto): Observable<string> {
    this.loadingDialog.open(LoadingDialogComponent);

    return this.http.post<string>(environment.apiUrl + environment.apiRoutes.feedbacks, feedbackDto).pipe(
      tap({
        next: message =>
          this.loadingDialog.setData({
            data: LoadingDialogState.Success,
            content: message
          })
      }),
      catchError(message => {
        this.loadingDialog.setData({
          data: LoadingDialogState.Error,
          content: message.error
        });
        return EMPTY;
      })
    );
  }

  getFeedbacks(): Observable<Array<FeedbackDto>> {
    return this.http.get<Array<FeedbackDto>>(environment.apiUrl + environment.apiRoutes.feedbacks);
  }

  getFeedback(feedbackId: string): Observable<FeedbackDto> {
    return this.http.get<FeedbackDto>(environment.apiUrl + environment.apiRoutes.feedbacks + '/' + feedbackId);
  }

  deleteFeedback(feedbackId: string): Observable<string> {
    this.loadingDialog.open(LoadingDialogComponent);

    return this.http.delete<string>(environment.apiUrl + environment.apiRoutes.feedbacks + '/' + feedbackId).pipe(
      tap({
        next: message =>
          this.loadingDialog.setData({
            data: LoadingDialogState.Success,
            content: message,
            afterClose: async () => {
              location.reload();
              return true;
            }
          })
      }),
      catchError(message => {
        this.loadingDialog.setData({
          data: LoadingDialogState.Error,
          content: message.error
        });
        return EMPTY;
      })
    );
  }
}
