<wv-card
  [title]="heading"
  class="h-full"
>
  <div class="flex flex-col gap-2 h-full">
    <div class="flex flex-1 items-center">
      <ng-content select="[statistic]"></ng-content>
    </div>
    <p class="p-0">{{ text | renderHTML }}</p>
  </div>
  <ng-content
    select="[info]"
    action
  ></ng-content>
</wv-card>
