import { PasswordPolicyDto } from '@ac/models';
import { Component, Input } from '@angular/core';
import { PasswordValidationErrors } from '@ac/colibri';

@Component({
  selector: 'ac-password-check',
  templateUrl: './password-check.component.html',
  styleUrls: ['./password-check.component.scss']
})
export class PasswordCheckComponent {
  passwordContainsDigit: boolean;
  passwordContainsLowercase: boolean;
  passwordContainsSpecialCharacter: boolean;
  passwordContainsUppercase: boolean;
  passwordHasRequiredMinimalLength: boolean;
  passwordHasRequiredCountOfUniqueChars: boolean;

  @Input() passwordPolicy: PasswordPolicyDto;

  @Input() set errors(errors: PasswordValidationErrors) {
    if (errors === null) {
      return;
    }

    this.passwordContainsDigit = !errors.includes('containsDigits');
    this.passwordContainsLowercase = !errors.includes('containsLowercase');
    this.passwordContainsSpecialCharacter = !errors.includes('containsSpecialCharacter');
    this.passwordContainsUppercase = !errors.includes('containsUppercase');
    this.passwordHasRequiredCountOfUniqueChars = !errors.includes('hasRequiredCountOfUniqueChars');
    this.passwordHasRequiredMinimalLength = !errors.includes('hasRequiredMinimalLength');
  }
}
