import {
  CompanyListDto,
  CreateWhitelistedAccessDto,
  DeleteWhitelistedAccessDto,
  ImportInsurancesResultDto,
  PasswordPolicyDto,
  RegisterCompanyDto,
  RequestAccessDto,
  ResendWhitelistedAccessDto,
  ResetPasswordCommand,
  ResetPasswordRequestCommand,
  TokenDto,
  UpdateDepartmentDto,
  UpdateEmailDto,
  UpdateEmailRequestDto,
  UpdateFirstNameDto,
  UpdateGenderDto,
  UpdateLastNameDto,
  UpdatePasswordDto,
  UserDto,
  UserInvitationListDto,
  UserLoginCommand,
  UserLoginResponse,
  RegisterUserDto,
  WhitelistedAccessDto
} from '@ac/models';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EMPTY, Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { UserDataDto } from '../../../../../libs/models/src/lib/identity/user-data.dto';
import { environment } from '@env';
import { LoadingDialogState } from '../layouts/loading-dialog/loading-dialog-state.enum';
import { LoadingDialogComponent } from '../layouts/loading-dialog/loading-dialog.component';
import { LoadingDialogService } from '../layouts/loading-dialog/loading-dialog.service';
import { UrlBuilder } from '../url-builder.service';
import { UserRole } from './user-role.type';

@Injectable({ providedIn: 'root' })
export class IdentityService {
  private loggedIn = false;

  constructor(
    private http: HttpClient,
    private loadingDialog: LoadingDialogService,
    private urlBuilder: UrlBuilder,
    private router: Router
  ) {
    this.loggedIn = !!localStorage.getItem('ac-auth_token');
  }

  // eslint-disable-next-line
  private static getTokenPayload(): any {
    const token = localStorage.getItem('ac-auth_token');

    if (!token) {
      return null;
    }

    const tokenPayload = token.split('.')[1];
    const escapedTokenPayload = tokenPayload.replace(/-/g, '+').replace(/_/g, '/'); //because of base64url variant
    const decodedTokenPayload = new TextDecoder().decode(
      Uint8Array.from(window.atob(escapedTokenPayload), c => c.charCodeAt(0))
    ); //decode from base64 to string and then to utf-8 which is the default of TextDecoder

    return JSON.parse(decodedTokenPayload);
  }

  private static enhanceInsuranceDateInformation(command: Partial<RequestAccessDto>): RequestAccessDto {
    return {
      ...command,
      day: command.insuranceDate.getDate(),
      month: command.insuranceDate.getMonth() + 1,
      year: command.insuranceDate.getFullYear()
    } as RequestAccessDto;
  }

  getUserById(userId: string): Observable<UserDto> {
    return this.http.get<UserDto>(environment.apiUrl + environment.apiRoutes.users + '/' + userId);
  }

  getUserId(): string {
    const payload = IdentityService.getTokenPayload();

    return payload?.sub;
  }

  getUserEmail(): string {
    const payload = IdentityService.getTokenPayload();

    return payload?.email;
  }

  getCompanyId(): string {
    const payload = IdentityService.getTokenPayload();

    return payload?.CompanyId;
  }

  getUserName(): string {
    const payload = IdentityService.getTokenPayload();

    return `${payload?.FirstName} ${payload?.LastName}`;
  }

  registerUser(registerUserDto: RegisterUserDto): Observable<string> {
    this.loadingDialog.open(LoadingDialogComponent);

    return this.http.post<string>(environment.apiUrl + environment.apiRoutes.registerUser, registerUserDto).pipe(
      tap({
        next: message =>
          this.loadingDialog.setData({
            data: LoadingDialogState.Success,
            content: message
          })
      }),
      catchError(message => {
        this.loadingDialog.setData({
          data: LoadingDialogState.Error,
          content: message.error
        });
        return EMPTY;
      })
    );
  }

  requestAccessForCompany(requestRegistrationCommandPartial: Partial<RequestAccessDto>): Observable<string> {
    this.loadingDialog.open(LoadingDialogComponent);

    const requestRegistrationCommand = IdentityService.enhanceInsuranceDateInformation(
      requestRegistrationCommandPartial
    );

    return this.http
      .post<string>(environment.apiUrl + environment.apiRoutes.requestAccess, requestRegistrationCommand)
      .pipe(
        tap({
          next: message =>
            this.loadingDialog.setData({
              data: LoadingDialogState.Success,
              content: message
            })
        }),
        catchError(message => {
          this.loadingDialog.setData({
            data: LoadingDialogState.Error,
            content: message.error
          });
          return EMPTY;
        })
      );
  }

  updateToken(): Observable<TokenDto> {
    return this.http.get<TokenDto>(environment.apiUrl + environment.apiRoutes.updateToken).pipe(
      tap(res => {
        if (res.authToken === undefined || res.authToken === null || !res.authToken.startsWith('ey')) {
          return false;
        }

        localStorage.setItem('ac-auth_token', res?.authToken);
        this.loggedIn = true;
        this.loadingDialog.close();
        return true;
      }),
      catchError(() => EMPTY)
    );
  }

  login(userLoginDto: UserLoginCommand): Observable<UserLoginResponse> {
    this.loadingDialog.open(LoadingDialogComponent);

    return this.http.post<UserLoginResponse>(environment.apiUrl + environment.apiRoutes.login, userLoginDto).pipe(
      tap(res => {
        if (res.authToken === undefined || res.authToken === null || !res.authToken.startsWith('ey')) {
          this.loadingDialog.setData({
            data: LoadingDialogState.Error,
            content: 'Beim Einloggen ist etwas schief gelaufen. Versuchen Sie es später erneut.'
          });
          return false;
        }

        localStorage.setItem('ac-auth_token', res?.authToken);
        this.loggedIn = true;
        this.loadingDialog.close();
        return true;
      }),
      catchError(res => {
        this.loadingDialog.setData({
          data: LoadingDialogState.Error,
          content: res.error
        });
        return EMPTY;
      })
    );
  }

  getUsers(): Observable<UserDto[]> {
    return this.http
      .get<UserDto[]>(environment.apiUrl + environment.apiRoutes.companies + '/' + this.getCompanyId() + '/Users')
      .pipe(map(users => this.removeSelfFromUsersList(users, this.getUserId())));
  }

  logout(): void {
    this.loggedIn = false;
    localStorage.removeItem('ac-auth_token');
  }

  isLoggedIn(): boolean {
    const tokenAvailable = localStorage.getItem('ac-auth_token') !== null;

    return this.loggedIn && tokenAvailable;
  }

  registerCompany(companyRegistrationCommand: RegisterCompanyDto): Observable<string> {
    this.loadingDialog.open(LoadingDialogComponent);

    return this.http
      .post<string>(environment.apiUrl + environment.apiRoutes.companies, companyRegistrationCommand)
      .pipe(
        tap({
          next: res =>
            this.loadingDialog.setData({
              data: LoadingDialogState.Success,
              content: res
            })
        }),
        catchError(res => {
          this.loadingDialog.setData({
            data: LoadingDialogState.Error,
            content: res.error
          });
          return EMPTY;
        })
      );
  }

  hasRole(role: UserRole): boolean {
    if (!this.isLoggedIn()) {
      return false;
    }

    const payload = IdentityService.getTokenPayload();

    return payload?.Role?.includes(role);
  }

  hasAnyRole(roles: UserRole | UserRole[]): boolean {
    if (Array.isArray(roles)) {
      return roles.some(role => this.hasRole(role));
    }

    return this.hasRole(roles);
  }

  isTokenExpired(): boolean {
    if (!this.isLoggedIn()) {
      return true;
    }

    const payload = IdentityService.getTokenPayload();
    const now = new Date(new Date()).getTime();
    const exp = new Date(payload?.exp * 1000).getTime();

    return now > exp;
  }

  requestPasswordReset(resetPasswordRequestCommand: ResetPasswordRequestCommand): Observable<string> {
    this.loadingDialog.open(LoadingDialogComponent);

    return this.http
      .post<string>(environment.apiUrl + environment.apiRoutes.resetPasswordRequest, resetPasswordRequestCommand)
      .pipe(
        tap({
          next: res =>
            this.loadingDialog.setData({
              data: LoadingDialogState.Success,
              content: res,
              afterClose: () => this.router.navigateByUrl(this.urlBuilder.goToLogin())
            })
        }),
        catchError(res => {
          this.loadingDialog.setData({
            data: LoadingDialogState.Error,
            content: res.error
          });
          return EMPTY;
        })
      );
  }

  resetPassword(resetPasswordCommand: ResetPasswordCommand): Observable<string> {
    this.loadingDialog.open(LoadingDialogComponent);

    return this.http.post<string>(environment.apiUrl + environment.apiRoutes.resetPassword, resetPasswordCommand).pipe(
      tap({
        next: res =>
          this.loadingDialog.setData({
            data: LoadingDialogState.Success,
            content: res,
            afterClose: () => this.router.navigateByUrl(this.urlBuilder.goToLogin())
          })
      }),
      catchError(res => {
        this.loadingDialog.setData({
          data: LoadingDialogState.Error,
          content: res.error
        });
        return EMPTY;
      })
    );
  }

  getPasswordPolicy(): Observable<PasswordPolicyDto> {
    return this.http.get<PasswordPolicyDto>(environment.apiUrl + environment.apiRoutes.passwordPolicy);
  }

  getUserData(): Observable<UserDataDto> {
    return this.http.get<UserDataDto>(environment.apiUrl + environment.apiRoutes.usersUserData);
  }

  updateGender(updateGenderCommand: UpdateGenderDto): Observable<string> {
    this.loadingDialog.open(LoadingDialogComponent);

    return this.http
      .patch<string>(environment.apiUrl + environment.apiRoutes.usersUpdateGender, updateGenderCommand)
      .pipe(
        tap({
          next: res =>
            this.loadingDialog.setData({
              data: LoadingDialogState.Success,
              content: res,
              afterClose: async () => {
                this.updateToken().subscribe();
                return true;
              }
            })
        }),
        catchError(res => {
          this.loadingDialog.setData({
            data: LoadingDialogState.Error,
            content: res.error
          });
          return EMPTY;
        })
      );
  }

  updateFirstName(updateFirstNameCommand: UpdateFirstNameDto): Observable<string> {
    this.loadingDialog.open(LoadingDialogComponent);

    return this.http
      .patch<string>(environment.apiUrl + environment.apiRoutes.usersUpdateFirstName, updateFirstNameCommand)
      .pipe(
        tap({
          next: res =>
            this.loadingDialog.setData({
              data: LoadingDialogState.Success,
              content: res,
              afterClose: async () => {
                this.updateToken().subscribe();
                return true;
              }
            })
        }),
        catchError(res => {
          this.loadingDialog.setData({
            data: LoadingDialogState.Error,
            content: res.error
          });
          return EMPTY;
        })
      );
  }

  updateLastName(updateLastNameCommand: UpdateLastNameDto): Observable<string> {
    this.loadingDialog.open(LoadingDialogComponent);

    return this.http
      .patch<string>(environment.apiUrl + environment.apiRoutes.usersUpdateLastName, updateLastNameCommand)
      .pipe(
        tap({
          next: res =>
            this.loadingDialog.setData({
              data: LoadingDialogState.Success,
              content: res,
              afterClose: async () => {
                this.updateToken().subscribe();
                return true;
              }
            })
        }),
        catchError(res => {
          this.loadingDialog.setData({
            data: LoadingDialogState.Error,
            content: res.error
          });
          return EMPTY;
        })
      );
  }

  sendUpdateEmailRequest(updateEmailRequestDto: UpdateEmailRequestDto): Observable<string> {
    this.loadingDialog.open(LoadingDialogComponent);

    return this.http
      .post<string>(environment.apiUrl + environment.apiRoutes.usersUpdateEmailRequest, updateEmailRequestDto)
      .pipe(
        tap({
          next: res =>
            this.loadingDialog.setData({
              data: LoadingDialogState.Success,
              content: res,
              afterClose: async () => {
                this.updateToken().subscribe();
                return true;
              }
            })
        }),
        catchError(res => {
          this.loadingDialog.setData({
            data: LoadingDialogState.Error,
            content: res.error
          });
          return EMPTY;
        })
      );
  }

  updateDepartment(updateDepartmentCommand: UpdateDepartmentDto): Observable<string> {
    this.loadingDialog.open(LoadingDialogComponent);

    return this.http
      .patch<string>(environment.apiUrl + environment.apiRoutes.usersUpdateDepartment, updateDepartmentCommand)
      .pipe(
        tap({
          next: res =>
            this.loadingDialog.setData({
              data: LoadingDialogState.Success,
              content: res,
              afterClose: async () => {
                this.updateToken().subscribe();
                return true;
              }
            })
        }),
        catchError(res => {
          this.loadingDialog.setData({
            data: LoadingDialogState.Error,
            content: res.error
          });
          return EMPTY;
        })
      );
  }

  updatePassword(updatePasswordCommand: UpdatePasswordDto): Observable<string> {
    this.loadingDialog.open(LoadingDialogComponent);

    return this.http
      .patch<string>(environment.apiUrl + environment.apiRoutes.usersUpdatePassword, updatePasswordCommand)
      .pipe(
        tap({
          next: res =>
            this.loadingDialog.setData({
              data: LoadingDialogState.Success,
              content: res,
              afterClose: async () => {
                this.updateToken().subscribe();
                return true;
              }
            })
        }),
        catchError(res => {
          this.loadingDialog.setData({
            data: LoadingDialogState.Error,
            content: res.error
          });
          return EMPTY;
        })
      );
  }

  updateEmail(updateEmailDto: UpdateEmailDto): Observable<string> {
    this.loadingDialog.open(LoadingDialogComponent);

    return this.http.patch<string>(environment.apiUrl + environment.apiRoutes.usersUpdateEmail, updateEmailDto).pipe(
      tap({
        next: res =>
          this.loadingDialog.setData({
            data: LoadingDialogState.Success,
            content: res,
            afterClose: async () => {
              if (this.isLoggedIn()) {
                this.updateToken().subscribe();
              }
              await this.router.navigateByUrl(this.urlBuilder.goToUserEdit());
              return true;
            }
          })
      }),
      catchError(res => {
        this.loadingDialog.setData({
          data: LoadingDialogState.Error,
          content: res.error
        });
        return EMPTY;
      })
    );
  }

  requestUserData(): Observable<string> {
    this.loadingDialog.open(LoadingDialogComponent);

    return this.http
      .post<string>(environment.apiUrl + environment.apiRoutes.users + '/' + this.getUserId() + '/gdpr', {})
      .pipe(
        tap({
          next: res =>
            this.loadingDialog.setData({
              data: LoadingDialogState.Success,
              content: res
            })
        }),
        catchError(res => {
          this.loadingDialog.setData({
            data: LoadingDialogState.Error,
            content: res.error
          });
          return EMPTY;
        })
      );
  }

  inviteUserFile(formData: FormData): Observable<string> {
    this.loadingDialog.open(LoadingDialogComponent);

    let headers = new HttpHeaders();

    headers = headers.append('enctype', 'multipart/form-data');

    return this.http
      .post<string>(environment.apiUrl + environment.apiRoutes.inviteUserFile, formData, { headers })
      .pipe(
        tap({
          next: res =>
            this.loadingDialog.setData({
              data: LoadingDialogState.Success,
              content: res,
              afterClose: async () => {
                location.reload();
                return true;
              }
            })
        }),
        catchError(res => {
          this.loadingDialog.setData({
            data: LoadingDialogState.Error,
            content: res.error
          });
          return EMPTY;
        })
      );
  }

  inviteUserList(userInvitationBulkListDto: UserInvitationListDto): Observable<string> {
    this.loadingDialog.open(LoadingDialogComponent);

    return this.http
      .post<string>(environment.apiUrl + environment.apiRoutes.inviteUserList, userInvitationBulkListDto)
      .pipe(
        tap({
          next: res =>
            this.loadingDialog.setData({
              data: LoadingDialogState.Success,
              content: res,
              afterClose: async () => {
                location.reload();
                return true;
              }
            })
        }),
        catchError(res => {
          this.loadingDialog.setData({
            data: LoadingDialogState.Error,
            content: res.error
          });
          return EMPTY;
        })
      );
  }

  resendInvitation(userId: string): Observable<string> {
    this.loadingDialog.open(LoadingDialogComponent);

    return this.http
      .post<string>(environment.apiUrl + environment.apiRoutes.userInvitations + '/' + userId + '/resend', {})
      .pipe(
        tap({
          next: res =>
            this.loadingDialog.setData({
              data: LoadingDialogState.Success,
              content: res
            })
        }),
        catchError(res => {
          this.loadingDialog.setData({
            data: LoadingDialogState.Error,
            content: res.error
          });
          return EMPTY;
        })
      );
  }

  whitelistAccess(dto: CreateWhitelistedAccessDto): Observable<string> {
    this.loadingDialog.open(LoadingDialogComponent);

    return this.http.post<string>(environment.apiUrl + environment.apiRoutes.whitelistedAccesses, dto).pipe(
      tap({
        next: res =>
          this.loadingDialog.setData({
            data: LoadingDialogState.Success,
            content: res,
            afterClose: async () => {
              location.reload();
              return true;
            }
          })
      }),
      catchError(res => {
        this.loadingDialog.setData({
          data: LoadingDialogState.Error,
          content: res.error
        });
        return EMPTY;
      })
    );
  }

  resendWhitelistInvitation(dto: ResendWhitelistedAccessDto): Observable<string> {
    this.loadingDialog.open(LoadingDialogComponent);

    return this.http.post<string>(environment.apiUrl + environment.apiRoutes.whitelistedAccesses + '/resend', dto).pipe(
      tap({
        next: res =>
          this.loadingDialog.setData({
            data: LoadingDialogState.Success,
            content: res
          })
      }),
      catchError(res => {
        this.loadingDialog.setData({
          data: LoadingDialogState.Error,
          content: res.error
        });
        return EMPTY;
      })
    );
  }

  importInsurances(hashedEntries: string[]): Observable<ImportInsurancesResultDto> {
    this.loadingDialog.open(LoadingDialogComponent);

    return this.http
      .post<ImportInsurancesResultDto>(environment.apiUrl + environment.apiRoutes.insurances, hashedEntries)
      .pipe(
        tap({
          next: res =>
            this.loadingDialog.setData({
              data: LoadingDialogState.Success,
              content: res.infoMessage,
              afterClose: async () => {
                return true;
              }
            })
        }),
        catchError(res => {
          this.loadingDialog.setData({
            data: LoadingDialogState.Error,
            content: res.error
          });
          return EMPTY;
        })
      );
  }

  getWhitelistedAccesses(): Observable<Array<WhitelistedAccessDto>> {
    return this.http.get<Array<WhitelistedAccessDto>>(environment.apiUrl + environment.apiRoutes.whitelistedAccesses);
  }

  getAssignableRolesForProductAdmin(): Observable<string[]> {
    return this.http.get<string[]>(environment.apiUrl + environment.apiRoutes.rolesProductAdmin);
  }

  getCompanies(): Observable<CompanyListDto[]> {
    return this.http.get<CompanyListDto[]>(environment.apiUrl + environment.apiRoutes.companies);
  }

  deleteWhitelistInvitation(dto: DeleteWhitelistedAccessDto): Observable<string> {
    this.loadingDialog.open(LoadingDialogComponent);

    return this.http.post<string>(environment.apiUrl + environment.apiRoutes.whitelistedAccesses + '/delete', dto).pipe(
      tap({
        next: res =>
          this.loadingDialog.setData({
            data: LoadingDialogState.Success,
            content: res,
            afterClose: async () => {
              location.reload();
              return true;
            }
          })
      }),
      catchError(res => {
        this.loadingDialog.setData({
          data: LoadingDialogState.Error,
          content: res.error
        });
        return EMPTY;
      })
    );
  }

  deleteInvitation(userId: string): Observable<string> {
    this.loadingDialog.open(LoadingDialogComponent);

    return this.http.delete<string>(environment.apiUrl + environment.apiRoutes.userInvitations + '/' + userId).pipe(
      tap({
        next: res =>
          this.loadingDialog.setData({
            data: LoadingDialogState.Success,
            content: res,
            afterClose: async () => {
              location.reload();
              return true;
            }
          })
      }),
      catchError(res => {
        this.loadingDialog.setData({
          data: LoadingDialogState.Error,
          content: res.error
        });
        return EMPTY;
      })
    );
  }

  deleteUser(userId: string): Observable<string> {
    this.loadingDialog.open(LoadingDialogComponent);

    return this.http.delete<string>(environment.apiUrl + environment.apiRoutes.users + '/' + userId).pipe(
      tap({
        next: res =>
          this.loadingDialog.setData({
            data: LoadingDialogState.Success,
            content: res,
            afterClose: async () => {
              location.reload();
              return true;
            }
          })
      }),
      catchError(res => {
        this.loadingDialog.setData({
          data: LoadingDialogState.Error,
          content: res.error
        });
        return EMPTY;
      })
    );
  }

  selfDeleteUser(userId: string, token: string) {
    this.loadingDialog.open(LoadingDialogComponent);

    return this.http
      .delete<string>(
        environment.apiUrl + environment.apiRoutes.userInvitations + '/self?userId=' + userId + '&token=' + token
      )
      .pipe(
        tap({
          next: res =>
            this.loadingDialog.setData({
              data: LoadingDialogState.Success,
              content: res,
              afterClose: () => this.router.navigateByUrl(this.urlBuilder.goToLogin())
            })
        }),
        catchError(res => {
          this.loadingDialog.setData({
            data: LoadingDialogState.Error,
            content: res.error,
            afterClose: () => this.router.navigateByUrl(this.urlBuilder.goToLogin())
          });
          return EMPTY;
        })
      );
  }

  checkInvitation(userId: string) {
    return this.http
      .post<string>(environment.apiUrl + environment.apiRoutes.userInvitations + '/check', { userId })
      .pipe(
        catchError(res => {
          this.loadingDialog.open(LoadingDialogComponent);
          this.loadingDialog.setData({
            data: LoadingDialogState.Error,
            content: res.error,
            afterClose: () => this.router.navigateByUrl(this.urlBuilder.goToLogin())
          });
          return EMPTY;
        })
      );
  }

  private removeSelfFromUsersList(users: UserDto[], userId: string): UserDto[] {
    return users.filter(user => user.id !== userId);
  }
}
