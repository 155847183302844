<div
  class="toolbar"
  role="heading"
>
  <div class="toolbar-row" id="toolbar-row">
    <a [routerLink]="urlBuilder.goToHome()">
      <h1
        data-testid="heading-title"
        class="navigation-title"
      >
        {{ 'navigation.title' | transloco }}
      </h1>
    </a>
    <ng-container *ngTemplateOutlet="shouldUseMobileMenu$$() ? mobileMenu : desktopMenu"></ng-container>
  </div>
</div>

<ng-template #mobileMenu>
  <div class="flex justify-end flex-1">
    <button
      (click)="toggleMobileMenu()"
      mat-icon-button
      matSuffix
      type="button"
      id="mobile-menu-button"
    >
      <mat-icon>{{ isMobileMenuOpen$$() ? 'menu_open' : 'menu' }}</mat-icon>
    </button>
  </div>

  <mat-drawer-container
    [hasBackdrop]="true"
    autosize
    class="!fixed !z-[1000] top-[--toolbar-height] left-0 w-full h-[calc(100%-var(--toolbar-height))] bg-amber-600 invisible"
  >
    <mat-drawer
      [opened]="isMobileMenuOpen$$()"
      mode="over"
      position="end"
      (openedChange)="isMobileMenuOpen$$.set($event)"
      id="mobile-menu"
    >
      <div class="flex flex-col py-4 h-full text-on-background">
        <h2 class="col-start-2 px-4 mb-4">Menü</h2>
        <a
          [routerLinkActiveOptions]="{ exact: false }"
          [routerLinkActive]="'active'"
          [routerLink]="urlBuilder.goToCourseOverview()"
          class="flex items-center gap-2 px-4 py-2 font-bold"
        >
          <mat-icon>play_lesson</mat-icon>
          <span>{{ 'navigation.menuItem.courseOverview' | transloco }}</span>
        </a>
        @if (identityService.hasRole('Cyber-Verantwortlicher')) {
          <a
            [routerLinkActiveOptions]="{ exact: false }"
            [routerLinkActive]="'active'"
            [routerLink]="urlBuilder.goToUserManagement()"
            class="flex items-center gap-2 px-4 py-2 font-bold"
          >
            <mat-icon>group</mat-icon>
            <span>{{ 'navigation.menuItem.userManagement' | transloco }}</span>
          </a>
        }
        <a
          [routerLinkActiveOptions]="{ exact: false }"
          [routerLinkActive]="'active'"
          [routerLink]="urlBuilder.goToPhishingMails()"
          class="flex items-center gap-2 px-4 py-2 font-bold"
        >
          <mat-icon>stacked_inbox</mat-icon>
          <span>{{ 'navigation.menuItem.phishingSimulations' | transloco }}</span>
        </a>
        <a
          [routerLinkActiveOptions]="{ exact: false }"
          [routerLinkActive]="'active'"
          [routerLink]="urlBuilder.goToToolkit()"
          class="flex items-center gap-2 px-4 py-2 font-bold"
        >
          <mat-icon>handyman</mat-icon>
          <span>{{ 'navigation.menuItem.toolkit' | transloco }}</span>
        </a>
        <a
          *ngIf="identityService.hasRole('Globaler Administrator') || identityService.hasRole('Produkt-Manager')"
          [routerLinkActiveOptions]="{ exact: false }"
          [routerLinkActive]="'active'"
          [routerLink]="urlBuilder.goToAdministration()"
          class="flex items-center gap-2 px-4 py-2 font-bold"
        >
          <mat-icon>admin_panel_settings</mat-icon>
          <span>{{ 'navigation.menuItem.administration' | transloco }}</span>
        </a>
        <a
          [routerLinkActiveOptions]="{ exact: false }"
          [routerLinkActive]="'active'"
          [routerLink]="urlBuilder.goToHelp()"
          class="flex items-center gap-2 px-4 py-2 font-bold"
        >
          <mat-icon>help</mat-icon>
          <span>{{ 'navigation.menuItem.help' | transloco }}</span>
        </a>
        @if (identityService.isLoggedIn()) {
          <div class="flex flex-col gap-0">
            <div class="flex items-center gap-2 px-4 py-2 font-bold">
              <mat-icon>account_circle</mat-icon>
              <span>{{ identityService.getUserName() }}</span>
            </div>
            <a
              [routerLinkActive]="'active'"
              [routerLink]="urlBuilder.goToUserEdit()"
              class="flex items-center gap-2 px-4 py-2"
              data-testid="goto-user-edit"
            >
              <mat-icon class="invisible">edit</mat-icon>
              <span>{{ 'navigation.editProfile' | transloco }}</span>
            </a>
            @if (identityService.hasRole('Cyber-Verantwortlicher')) {
              <a [routerLink]="urlBuilder.goToCompanyEdit()" [routerLinkActive]="'active'"
                 class="flex items-center gap-2 px-4 py-2">
                <mat-icon class="invisible">apartment</mat-icon>
                <span>{{ 'navigation.editCompany' | transloco }}</span>
              </a>
              <a [routerLink]="urlBuilder.goToSubscriptionOverview()" [routerLinkActive]="'active'"
                 class="flex items-center gap-2 px-4 py-2">
                <mat-icon class="invisible">card_membership</mat-icon>
                <span>{{ 'navigation.manageSubscription' | transloco }}</span>
              </a>
            }
            @if(identityService.hasRole('Redakteur')){
              <a
                [routerLink]="urlBuilder.goToCms()"
                [routerLinkActive]="'active'"
                class="flex items-center gap-2 px-4 py-2"
              >
                <mat-icon class="invisible">cast_for_education</mat-icon>
                <span>{{ 'navigation.manageCourses' | transloco }}</span>
              </a>
            }
          </div>
        }
        <div class="flex flex-1 flex-col justify-end">
          @if (identityService.isLoggedIn()) {
            <a [routerLink]="urlBuilder.goToLogout()"
               class="flex items-center gap-2 px-4 py-2 font-bold">
              <mat-icon>logout</mat-icon>
              <span>{{ 'navigation.logout' | transloco }}</span>
            </a>
          } @else {
            <a [routerLink]="urlBuilder.goToLogin()">
              <mat-icon>login</mat-icon>
              <span>{{ 'navigation.login' | transloco }}</span>
            </a>
          }
        </div>
      </div>
    </mat-drawer>
  </mat-drawer-container>
</ng-template>

<ng-template #desktopMenu>
  <div class="flex justify-between flex-1 h-full">
    <div
      class="navigation-items"
      data-test="menu-logged-in"
    >
      <a
        [routerLinkActiveOptions]="{ exact: false }"
        [routerLinkActive]="'active'"
        [routerLink]="urlBuilder.goToCourseOverview()"
        class="navigation-item"
      >
        {{ 'navigation.menuItem.courseOverview' | transloco }}
      </a>
      <a
        *ngIf="identityService.hasRole('Cyber-Verantwortlicher')"
        [routerLinkActiveOptions]="{ exact: false }"
        [routerLinkActive]="'active'"
        [routerLink]="urlBuilder.goToUserManagement()"
        class="navigation-item"
      >
        {{ 'navigation.menuItem.userManagement' | transloco }}
      </a>
      <a
        [routerLinkActiveOptions]="{ exact: false }"
        [routerLinkActive]="'active'"
        [routerLink]="urlBuilder.goToPhishingMails()"
        class="navigation-item"
      >
        {{ 'navigation.menuItem.phishingSimulations' | transloco }}
      </a>
      <a
        [routerLinkActiveOptions]="{ exact: false }"
        [routerLinkActive]="'active'"
        [routerLink]="urlBuilder.goToToolkit()"
        class="navigation-item"
      >
        {{ 'navigation.menuItem.toolkit' | transloco }}
      </a>
      <a
        *ngIf="identityService.hasRole('Globaler Administrator') || identityService.hasRole('Produkt-Manager')"
        [routerLinkActiveOptions]="{ exact: false }"
        [routerLinkActive]="'active'"
        [routerLink]="urlBuilder.goToAdministration()"
        class="navigation-item"
      >
        {{ 'navigation.menuItem.administration' | transloco }}
      </a>
      <a
        [routerLinkActiveOptions]="{ exact: false }"
        [routerLinkActive]="'active'"
        [routerLink]="urlBuilder.goToHelp()"
        class="navigation-item"
      >
        {{ 'navigation.menuItem.help' | transloco }}
      </a>
    </div>
    <div class="navigation-actions">
      <div *ngIf="!identityService.isLoggedIn()">
        <a
          [routerLink]="urlBuilder.goToLogin()"
          class="login navigation-item"
        >
          <mat-icon>lock</mat-icon>
          <span>{{ 'navigation.login' | transloco }}</span>
        </a>
      </div>
      <div
        (click)="isUserMenuOpen = !isUserMenuOpen"
        *ngIf="identityService.isLoggedIn()"
        [matMenuTriggerFor]="userMenu"
        class="user-menu-container navigation-item"
        data-test="box-user-menu"
        data-testid="box-user-menu"
      >
        <mat-icon class="icon-filled">account_circle</mat-icon>
        {{ identityService.getUserName() }}
        <mat-icon>{{ isUserMenuOpen ? 'expand_less' : 'expand_more' }}</mat-icon>
      </div>

      <mat-menu
        #userMenu="matMenu"
        class="user-menu"
        (closed)="isUserMenuOpen = false"
      >
        <a
          [routerLinkActive]="'active'"
          [routerLink]="urlBuilder.goToUserEdit()"
          data-testid="goto-user-edit"
          class=""
          mat-menu-item
        >
          <mat-icon>edit</mat-icon>
          {{ 'navigation.editProfile' | transloco }}
        </a>
        @if (identityService.hasRole('Cyber-Verantwortlicher')) {
          <a mat-menu-item [routerLink]="urlBuilder.goToCompanyEdit()" [routerLinkActive]="'active'">
            <mat-icon>apartment</mat-icon>
            {{ 'navigation.editCompany' | transloco }}
          </a>
          <a mat-menu-item [routerLink]="urlBuilder.goToSubscriptionOverview()" [routerLinkActive]="'active'">
            <mat-icon>card_membership</mat-icon>
            {{ 'navigation.manageSubscription' | transloco }}
          </a>
        }
        @if(identityService.hasRole('Redakteur')){
          <a
            mat-menu-item
            [routerLink]="urlBuilder.goToCms()"
            [routerLinkActive]="'active'"
          >
            <mat-icon>cast_for_education</mat-icon>
            {{ 'navigation.manageCourses' | transloco }}
          </a>
        }
        <ng-container *ngIf="identityService.isLoggedIn()">
          <mat-divider></mat-divider>
          <a [routerLink]="urlBuilder.goToLogout()" mat-menu-item>
            <mat-icon>logout</mat-icon>
            {{ 'navigation.logout' | transloco }}
          </a>
        </ng-container>
        <ng-container *ngIf="!identityService.isLoggedIn()">
          <mat-divider></mat-divider>
          <a
            [routerLink]="urlBuilder.goToLogin()"
            mat-menu-item
          >
            <mat-icon>login</mat-icon>
            {{ 'navigation.login' | transloco }}
          </a>
        </ng-container>
      </mat-menu>
    </div>
  </div>
</ng-template>
