import { AfterViewInit, Directive, ElementRef } from '@angular/core';
import { LoadingDialogService } from '@app/layouts/loading-dialog/loading-dialog.service';
import { LoadingDialogState } from '@app/layouts/loading-dialog/loading-dialog-state.enum';
import { LoadingDialogComponent } from '@app/layouts/loading-dialog/loading-dialog.component';
import { TranslocoService } from '@ngneat/transloco';

@Directive({
  selector: 're-captcha'
})
export class RecaptchaMustBeVisibleDirective implements AfterViewInit {
  constructor(
    private elementRef: ElementRef,
    private loadingDialog: LoadingDialogService,
    private transloco: TranslocoService
  ) {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      const recaptcha = document.querySelector('re-captcha');

      if (!recaptcha) {
        return;
      }

      const isRecaptchaVisible = this.isRecaptchaVisible();

      if (!isRecaptchaVisible) {
        this.loadingDialog.open(LoadingDialogComponent);
        this.loadingDialog.setData({
          data: LoadingDialogState.Error,
          content: this.transloco.translate('app.ad-blocker-detected'),
          afterClose: () => {
            location.reload();
            return new Promise(() => true);
          }
        });
      }
    }, 4000);
  }

  private isRecaptchaVisible(): boolean {
    const iframe = this.elementRef.nativeElement.querySelector('re-captcha iframe');

    return (
      iframe &&
      getComputedStyle(iframe).display !== 'none' &&
      iframe.clientHeight !== 0 &&
      iframe.clientWidth !== 0 &&
      iframe.getClientRects().length !== 0
    );
  }
}
