import { Directive, TemplateRef, ViewContainerRef, inject, afterNextRender, DestroyRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { activeCourseContext } from '../store/course.selectors';
import { tap } from 'rxjs/operators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Directive({
  selector: '[ifGlobalCourseContextIsActive]',
  standalone: true
})
export class IfGlobalCourseContextIsActiveDirective {
  #destroyRef = inject(DestroyRef);
  #templateRef = inject(TemplateRef<HTMLElement>);
  #viewContainer = inject(ViewContainerRef);

  #store = inject(Store);

  constructor() {
    afterNextRender(() => this.#renderTemplateIfCompanyCourseContextIsActive());
  }

  #renderTemplateIfCompanyCourseContextIsActive() {
    this.#store
      .select(activeCourseContext)
      .pipe(
        tap(context => {
          if (context === 'CourseCMS') this.#viewContainer.createEmbeddedView(this.#templateRef);
          else this.#viewContainer.clear();
        }),
        takeUntilDestroyed(this.#destroyRef)
      )
      .subscribe();
  }
}
