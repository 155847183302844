<mat-icon #trigger="cdkOverlayOrigin" (click)="isOpen = !isOpen" cdkOverlayOrigin class="info-button">
  info_outline
</mat-icon>

<ng-template
  (overlayOutsideClick)="isOpen = !isOpen"
  [cdkConnectedOverlayOpen]="isOpen"
  [cdkConnectedOverlayOrigin]="trigger"
  cdkConnectedOverlay
>
  <mat-card class="info-box mat-elevation-z2">
    <ng-container *ngIf="description">
      <h4>{{ 'info-box.common.descriptionTitle' | transloco | renderHTML }}</h4>
      <p [innerHTML]="description"></p>
    </ng-container>
    <ng-container *ngIf="gdpr">
      <h4>{{ 'info-box.common.gdprTitle' | transloco | renderHTML }}</h4>
      <p [innerHTML]="gdpr"></p>
      <p>{{ 'info-box.common.disclaimer' | transloco: {link: environment.urls.privacy} | renderHTML }}</p>
    </ng-container>
  </mat-card>
</ng-template>
