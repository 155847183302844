@if (course$ | async; as course) {
  <app-back-layout
    class="-translate-x-12"
    [backUrl]="backToListPath$ | async"
    [title]="pageTitle"
  ></app-back-layout>
  <form [formGroup]="formGroup">
    <span>
      <small>{{ 'cms.course.id' | transloco }}: {{ course.id }}</small>
    </span>
    <mat-form-field>
      <input
        [placeholder]="'cms.course.title' | transloco"
        formControlName="title"
        matInput
      />
    </mat-form-field>
    <mat-form-field>
      <textarea
        [placeholder]="'cms.course.abstract' | transloco"
        formControlName="abstract"
        matInput
        rows="4"
      ></textarea>
    </mat-form-field>
    <mat-form-field>
      <textarea
        [placeholder]="'cms.course.description' | transloco"
        formControlName="description"
        matInput
        rows="4"
      ></textarea>
    </mat-form-field>
    <mat-form-field>
      <input
        [placeholder]="'cms.course.imageUrl' | transloco"
        formControlName="imageUrl"
        matInput
      />
    </mat-form-field>

    <ng-container *ifGlobalCourseContextIsActive>
      <mat-checkbox formControlName="hasExam">{{ 'cms.course.examAvailable' | transloco }}</mat-checkbox>
      <mat-form-field>
        <input
          [placeholder]="'cms.course.numberOfTasks' | transloco"
          formControlName="numberOfTasks"
          matInput
          type="number"
        />
      </mat-form-field>
      <mat-form-field>
        <input
          [placeholder]="'cms.course.requiredNumberOfCorrectAnswers' | transloco"
          formControlName="requiredNumberOfCorrectAnswers"
          matInput
          type="number"
        />
      </mat-form-field>
    </ng-container>
    <div>
      <h3>{{ 'cms.module.heading' | transloco }}</h3>
      <button
        (click)="addModule()"
        color="primary"
        mat-raised-button
      >
        {{ 'cms.addButton' | transloco }}
      </button>
      <div
        (cdkDropListDropped)="drop($event)"
        cdkDropList
        class="mt-4"
      >
        <app-module-list-element
          *ngFor="let module of course.modules"
          [module]="module"
          cdkDrag
        >
          <button
            (click)="removeModule(module.id)"
            [matTooltip]="'tooltip.delete' | transloco"
            mat-icon-button
          >
            <mat-icon>delete</mat-icon>
          </button>
          <app-drag-and-drop-handle cdkDragHandle></app-drag-and-drop-handle>
        </app-module-list-element>
      </div>
    </div>
  </form>
}
