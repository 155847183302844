import {Component, Input} from '@angular/core';

@Component({
  selector: 'awareness-center-statistic-card',
  templateUrl: './statistic-card.component.html',
  styleUrls: ['./statistic-card.component.scss']
})
export class StatisticCardComponent {
  @Input({required: true}) text = '';
  @Input({required: true}) heading = '';
}
