import {inject, Injectable} from "@angular/core";
import {MatDialog} from "@angular/material/dialog";
import {ConfirmationDialogComponent} from "./confirmation-dialog.component";
import {ConfirmationData} from "./confirmation-data";
import {firstValueFrom} from "rxjs";

@Injectable({providedIn: 'root'})
export class ConfirmationService {
  #dialog = inject(MatDialog);

  async confirm(data: ConfirmationData): Promise<boolean> {
    data.confirmLabel ??= "TestConfirm";
    data.cancelLabel ??= "TestCancel";

    const dialog = this.#dialog.open(ConfirmationDialogComponent,
        {
          data: data
        });

    return await firstValueFrom(dialog.afterClosed());
  }
}
