import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-back-arrow-button',
  templateUrl: './back-arrow-button.component.html',
  styleUrls: ['./back-arrow-button.component.scss']
})
export class BackArrowButtonComponent {
  @Input() url: string = '';
}
