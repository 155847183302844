<mat-card class="resource-card mat-elevation-z2">
  <mat-card-content class="resource-content">
    <h4 *ngIf="title" class="resource-title">{{ title }}</h4>
    <div *ngIf="module || lesson">
      <small *ngIf="module" class="course-info">
        <i>{{ 'resource.module' | transloco }}:</i>
        {{ module }}
      </small>
      <small *ngIf="lesson" class="course-info">
        <i>{{ 'resource.lesson' | transloco }}:</i>
        {{ lesson }}
      </small>
    </div>
    <p *ngIf="description" class="resource-description">{{ description }}</p>
  </mat-card-content>
  <mat-card-actions>
    <a *ngIf="url" [href]="url" target="_blank">
      <button appearance="secondary" wvButton>{{ 'resource.open' | transloco }}</button>
    </a>
  </mat-card-actions>
</mat-card>
