import { HttpClient } from '@angular/common/http';
import { Injectable, LOCALE_ID, NgModule } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import {
  getBrowserLang,
  HashMap,
  provideTransloco,
  Translation,
  TranslocoLoader,
  TranslocoModule,
  TranslocoService
} from '@ngneat/transloco';
import { RECAPTCHA_LANGUAGE } from 'ng-recaptcha';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from '@env';
import moment from 'moment/moment';
import 'moment/locale/de';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  constructor(private http: HttpClient) {}

  getTranslation(language: string): Observable<HashMap> {
    return this.http.get<Translation>('./assets/i18n/' + language + '.json');
  }
}

@NgModule({
  imports: [TranslocoModule],
  exports: [TranslocoModule],
  providers: [
    provideTransloco({
      config: {
        availableLangs: environment.config.translate.availableLanguages,
        defaultLang: environment.config.translate.defaultLanguage,
        fallbackLang: environment.config.translate.fallbackLanguage,
        reRenderOnLangChange: environment.config.translate.renderOnLanguageChange,
        prodMode: environment.metadata.environment === 'production'
      },
      loader: TranslocoHttpLoader
    }),
    {
      provide: RECAPTCHA_LANGUAGE,
      useFactory: getCurrentLanguage,
      deps: [TranslocoService]
    },
    {
      provide: LOCALE_ID,
      useFactory: getCurrentLocale,
      deps: [TranslocoService]
    }
  ]
})
export class TranslateModule {
  constructor(
    private translocoService: TranslocoService,
    private dateAdapter: DateAdapter<unknown>
  ) {
    if (environment.config.translate.useBrowserLanguage) {
      this.translocoService.setActiveLang(
        detectLanguage(translocoService.getAvailableLangs() as string[], translocoService.getDefaultLang())
      );
    }
    this.translocoService.langChanges$.subscribe(() => {
      const locale = resolveLocaleByLanguage(translocoService.getActiveLang());
      this.dateAdapter.setLocale(locale);
      moment.locale(locale);
    });
  }
}

function detectLanguage(availableLanguages: string[], defaultLanguage: string): string {
  const browserLanguage: string = getBrowserLang();

  if (availableLanguages.includes(browserLanguage)) {
    return browserLanguage;
  }
  return defaultLanguage;
}

function getCurrentLanguage(translocoService: TranslocoService): string {
  return translocoService.getActiveLang();
}

function getCurrentLocale(translocoService: TranslocoService): string {
  return resolveLocaleByLanguage(translocoService.getActiveLang());
}

function resolveLocaleByLanguage(language: string): string {
  if (language === 'de') {
    return 'de-DE';
  }
  return 'en-EN';
}
