import {Injectable, signal} from "@angular/core";

@Injectable({providedIn: 'root'})
export class RecaptchaConsentService {
  #localStorageKey = "recaptcha-consent";
  #consentGiven = signal(false);

  constructor() {
    const consentGiven = localStorage.getItem(this.#localStorageKey);
    if (consentGiven === 'true') {
      this.#consentGiven.set(true);
    }
  }

  giveConsent() {
    localStorage.setItem(this.#localStorageKey, "true");
    this.#consentGiven.set(true);
  }

  hasGiveConsent(){
    return this.#consentGiven();
  }

}
