import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({ selector: '[acDisableOnClick]' })
export class DisableOnClickDirective {
  constructor(private host: ElementRef<HTMLElement>, private renderer: Renderer2) {}

  @HostListener('click')
  disableHost() {
    this.renderer.setAttribute(this.host.nativeElement, 'disabled', 'true');
  }
}
