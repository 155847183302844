import { environment as environmentBase } from './environment.base';

export const environment = {
  ...environmentBase,
  metadata: {
    name: 'cp',
    description: 'cyber portal',
    environment: 'staging'
  },
  baseUrl: 'https://beta-wuerttembergische.co-IT.eu',
  apiUrl: 'https://beta-api-wuerttembergische.co-IT.eu',
  secrets: {
    RECAPTCHAV2KEY: '6LeUm10aAAAAAMme535NSraQ5hh11bYtPZlVNrUr'
  },
  features: {
    MAINTENANCE: false
  },
  subscriptions: {
    defaultAwarenessPageRedirectUrl: 'https://beta-wuerttembergische.co-it.eu/cyber-portal/phishing-mail-clicked'
  }
};
