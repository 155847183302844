import { Component, ElementRef, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'button[wvButton]',
  templateUrl: './wv-button.component.html',
  styleUrls: ['./wv-button.component.scss']
})
export class WvButtonComponent {
  @Input() materialIcon: string;
  @Input() appearance:
    | 'primary'
    | 'secondary'
    | 'outline-secondary'
    | 'small-secondary'
    | 'small-primary'
    | 'compact-secondary'
    | 'compact-primary'
    | 'outline-warning' = 'secondary';

  constructor(private host: ElementRef<HTMLButtonElement>) {}

  @HostBinding('class.wv-button--primary')
  get isPrimary() {
    return this.appearance === 'primary';
  }

  @HostBinding('class.wv-button--secondary')
  get isSecondary() {
    return this.appearance === 'secondary';
  }

  @HostBinding('class.wv-button--small-secondary')
  get isSmallSecondary() {
    return this.appearance === 'small-secondary';
  }

  @HostBinding('class.wv-button--small-primary')
  get isSmallPrimary() {
    return this.appearance === 'small-primary';
  }

  @HostBinding('class.wv-button--compact-secondary')
  get isCompactSecondary() {
    return this.appearance === 'compact-secondary';
  }

  @HostBinding('class.wv-button--compact-primary')
  get isCompactPrimary() {
    return this.appearance === 'compact-primary';
  }

  @HostBinding('class.wv-button--outline-secondary')
  get isOutlineSecondary() {
    return this.appearance === 'outline-secondary';
  }

  @HostBinding('class.wv-button--outline-warning')
  get isOutlineWarning() {
    return this.appearance === 'outline-warning';
  }

  @HostBinding('class.wv-button--disabled')
  get isDisabled() {
    return this.host?.nativeElement?.disabled;
  }
}
