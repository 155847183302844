import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Subject } from 'rxjs';
import { LoadingDialogData } from './loading-dialog-data';
import { LoadingDialogState } from './loading-dialog-state.enum';

@Component({
  selector: 'app-loading-dialog',
  templateUrl: './loading-dialog.component.html',
  styleUrls: ['./loading-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LoadingDialogComponent {
  LoadingDialogState = LoadingDialogState;

  constructor(@Inject(MAT_DIALOG_DATA) public dialogData$$: Subject<LoadingDialogData>) {}
}
