<circle-progress
  [animation]="true"
  [backgroundPadding]="0"
  [innerStrokeColor]="innerStrokeColor"
  [innerStrokeWidth]="8"
  [outerStrokeColor]="'var(--secondary)'"
  [outerStrokeWidth]="showCircle ? 8 : 0"
  [percent]="percentage"
  [radius]="60"
  [responsive]="false"
  [showInnerStroke]="showCircle"
  [showSubtitle]="false"
  [space]="-8"
  [titleColor]="'var(--on-surface)'"
  [titleFontSize]="'40'"
  [unitsColor]="'var(--on-surface)'"
  [unitsFontSize]="'30'"
></circle-progress>
