<header *ngIf="backUrl != null || title" [class.with-navigation]="backUrl && title" class="back-layout__header">
  @if(backUrl != null){
    <app-back-arrow-button [url]="backUrl" class="button"></app-back-arrow-button>
  } @else {
    <div></div>
  }
  <h2 *ngIf="title" class="title">{{ title }}</h2>
</header>

<section class="back-layout__content">
  <ng-content></ng-content>
</section>
