import { ElementRef, Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'renderHTML' })
export class RenderHTMLPipe implements PipeTransform {
  constructor(private element: ElementRef) {}

  transform(value: string): string {
    if (value && this.element.nativeElement.parentNode) {
      this.element.nativeElement.parentNode.innerHTML += value;
    }

    return value;
  }
}
