import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'app-back-layout',
  templateUrl: './back-layout.component.html',
  styleUrls: ['./back-layout.component.scss']
})
export class BackLayoutComponent {
  @HostBinding('class.align-content-to-navigation')
  @Input() align = true;

  @Input() title: string;
  @Input() backUrl: string;
}
