import {
  AfterViewInit,
  Component,
  computed,
  effect,
  ElementRef,
  input,
  OnDestroy,
  output,
  viewChild,
  ViewEncapsulation
} from '@angular/core';
import 'vidstack/player';
import 'vidstack/player/ui';
import 'vidstack/icons';
import { VideoDto } from '../../../../models/src/lib/course-player/video.dto';
import type { MediaPlayerElement } from 'vidstack/elements';
import { isHLSProvider, MediaProviderChangeEvent, TextTrack } from 'vidstack';
import Hls from 'hls.js';
import { EMPTY, interval, Observable } from 'rxjs';
import { scan, takeWhile, tap } from 'rxjs/operators';
import { CustomLocalMediaStorage } from './custom-local-media-storage';

@Component({
  selector: 'app-videoplayer',
  templateUrl: './videoplayer.component.html',
  styleUrls: ['./videoplayer.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class VideoplayerComponent implements AfterViewInit, OnDestroy {
  #player = computed(() => this.playerRef().nativeElement);

  video = input.required<VideoDto>();
  playerRef = viewChild.required<ElementRef<MediaPlayerElement>>('player');

  title = input('');
  autoplay = input(true);
  delay = input(0);
  resetOnEnd = input(false);

  videoEnded = output<void>();
  spinnerEnded = output<void>();

  protected endingSpinner$: Observable<number> = EMPTY;
  protected endingSpinnerVisible = false;

  constructor() {
    effect(() => this.#updateTextTracksWhenVideoHasChanged());
  }

  ngAfterViewInit(): void {
    this.#player().storage = new CustomLocalMediaStorage('media-player');
    this.#player().addEventListener('provider-change', this.#handleProviderChange);
    this.#player().addEventListener('end', this.#handleEnd);
  }

  ngOnDestroy(): void {
    this.#player().removeEventListener('provider-change', this.#handleProviderChange);
    this.#player().removeEventListener('end', this.#handleEnd);
  }

  #handleEnd = () => {
    this.videoEnded.emit();

    if (this.resetOnEnd() && this.#player().provider) this.#player().provider?.setCurrentTime(0);

    if (this.delay() === 0) return;

    this.endingSpinner$ = this.setUpEndingSpinner(this.delay());
  };

  #handleProviderChange = (event: MediaProviderChangeEvent) => {
    const provider = event.detail;

    if (isHLSProvider(provider)) {
      provider.library = Hls;
    }
  };

  private setUpEndingSpinner(seconds: number): Observable<number> {
    this.endingSpinnerVisible = true;

    const msPerPercent = (seconds * 1000) / 100;

    return interval(msPerPercent).pipe(
      scan(total => total + 1, 0),
      takeWhile(total => total < 110),
      tap({
        complete: () => {
          this.spinnerEnded.emit();
          this.endingSpinnerVisible = false;
        }
      })
    );
  }

  #updateTextTracksWhenVideoHasChanged() {
    const video = this.video();
    const playerRef = this.playerRef();

    const tracks =
      video.captions?.map(
        caption =>
          new TextTrack({
            src: caption.source,
            language: caption.language,
            type: 'vtt',
            kind: 'subtitles',
            label: caption.label
          })
      ) || [];

    playerRef.nativeElement.textTracks.clear();
    tracks.forEach(track => playerRef.nativeElement.textTracks.add(track));
  }
}
