import { Injectable } from '@angular/core';
import { TextDifferResult } from './text-collection-differ-result';

@Injectable({ providedIn: 'root' })
export class TextCollectionDiffer {
  diff(previous: string[], next: string[]): TextDifferResult {
    const additions = next.filter(newOne => !previous.includes(newOne));
    const removals = previous.filter(existing => !next.includes(existing));

    return { additions, removals };
  }
}
