import { Component, Input } from '@angular/core';

@Component({
  selector: 'awareness-center-resource-card',
  templateUrl: './resource-card.component.html',
  styleUrls: ['./resource-card.component.scss']
})
export class ResourceCardComponent {
  @Input() title: string;
  @Input() module: string;
  @Input() lesson: string;
  @Input() description: string;
  @Input() url: string;
}
