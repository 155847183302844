import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'awareness-center-wv-chevron-link',
  templateUrl: './wv-chevron-link.component.html',
  styleUrls: ['./wv-chevron-link.component.scss']
})
export class WvChevronLinkComponent {
  @Input() url: string;
  @Input() text: string;
  @Input() appearance: 'primary' | 'secondary' | 'default' = 'secondary';

  @HostBinding('class.wv-chevron--primary')
  get isPrimary() {
    return this.appearance === 'primary';
  }

  @HostBinding('class.wv-chevron--secondary')
  get isSecondary() {
    return this.appearance === 'secondary';
  }

  @HostBinding('class.wv-chevron--default')
  get isDefault() {
    return this.appearance === 'default';
  }
}
