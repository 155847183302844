import {ChangeDetectionStrategy, Component, inject} from '@angular/core';
import { CommonModule } from '@angular/common';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ConfirmationData} from "./confirmation-data";
import {ColibriModule} from "../colibri.module";

@Component({
  selector: 'ac-confirmation-dialog',
  standalone: true,
  imports: [CommonModule, ColibriModule],
  templateUrl: './confirmation-dialog.component.html',
  styleUrl: './confirmation-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmationDialogComponent {
  #dialog = inject(MatDialogRef);
  confirmationData = inject<ConfirmationData>(MAT_DIALOG_DATA);

  confirm() {
    this.#dialog.close(true);
  }

  cancel() {
    this.#dialog.close(false);
  }
}
