@if (video(); as video) {
  <media-player
    #player
    [title]="title()"
    [src]="video.streamUrl"
    [autoplay]="autoplay()"
    playsinline
    load="visible"
    view-type="video"
    stream-type="on-demand"
    crossOrigin="anonymous"
    storage="media-player"
    key-target="document"
    data-testid="mediaplayer"
    class="media-player ring-media-focus aspect-video w-full bg-slate-950 text-white data-[focus]:ring-4"
  >
    <media-provider>
      <media-poster
        class="absolute inset-0 block h-full w-full rounded-md bg-black opacity-0 transition-opacity data-[visible]:opacity-100 [&>img]:h-full [&>img]:w-full [&>img]:object-cover"
      ></media-poster>
    </media-provider>
    <!-- Gestures -->
    <media-gesture
      class="absolute inset-0 z-0 block h-full w-full"
      event="pointerup"
      action="toggle:paused"
    ></media-gesture>
    <media-gesture
      class="absolute inset-0 z-0 block h-full w-full"
      event="dblpointerup"
      action="toggle:fullscreen"
    ></media-gesture>
    <media-gesture
      class="absolute left-0 top-0 z-10 block h-full w-1/5"
      event="dblpointerup"
      action="seek:-10"
    ></media-gesture>
    <media-gesture
      class="absolute right-0 top-0 z-10 block h-full w-1/5"
      event="dblpointerup"
      action="seek:10"
    ></media-gesture>

    <!-- Captions -->
    <media-captions
      class="absolute inset-0 bottom-2 z-20 select-none break-words opacity-0 transition-[opacity,bottom] duration-300 media-captions:opacity-100 media-controls:bottom-[85px]"
    ></media-captions>

    <!-- Controls -->
    <media-controls
      class="media-controls:opacity-100 absolute inset-0 z-10 flex h-full w-full flex-col bg-gradient-to-t from-black via-transparent via-30% to-transparent opacity-0 transition-opacity"
    >
      <div class="pointer-events-none flex-1"></div>
      <media-controls-group
        class="flex w-full items-center px-2"
        data-testid="media-control-group"
      >
        <!-- Time Slider -->
        <media-time-slider
          class="group relative mx-[7.5px] inline-flex h-10 w-full cursor-pointer touch-none select-none items-center outline-none"
        >
          <media-slider-chapters class="relative flex h-full w-full items-center rounded-[1px]">
            <template>
              <!-- Slider Chapter -->
              <div
                class="last-child:mr-0 relative mr-0.5 flex h-full w-full items-center rounded-[1px]"
                style="contain: layout style"
              >
                <!-- Slider Chapter Track -->
                <div
                  class="ring-media-focus relative z-0 h-[5px] w-full rounded-sm bg-white/30 group-data-[focus]:ring-[3px]"
                >
                  <div
                    class="bg-media-brand absolute z-20 h-full w-[var(--chapter-fill)] rounded-sm will-change-[width]"
                  ></div>
                  <div
                    class="absolute z-10 h-full w-[var(--chapter-progress)] rounded-sm bg-white/50 will-change-[width]"
                  ></div>
                </div>
              </div>
            </template>
          </media-slider-chapters>
          <!-- Slider Thumb -->
          <div
            class="absolute left-[var(--slider-fill)] top-1/2 z-20 h-[15px] w-[15px] -translate-x-1/2 -translate-y-1/2 rounded-full border border-[#cacaca] bg-white opacity-0 ring-white/40 transition-opacity will-change-[left] group-data-[active]:opacity-100 group-data-[dragging]:ring-4"
          ></div>
          <!-- Slider Preview -->
          <media-slider-preview
            class="pointer-events-none flex flex-col items-center opacity-0 transition-opacity duration-200 data-[visible]:opacity-100"
          >
            <div
              class="mt-1 text-sm"
              data-part="chapter-title"
            ></div>
            <media-slider-value
              class="animate-out fade-out slide-out-to-bottom-2 data-[visible]:animate-in data-[visible]:fade-in data-[visible]:slide-in-from-bottom-4 z-10 rounded-sm bg-black/90 px-2 py-0.5 text-sm font-medium text-white"
            ></media-slider-value>
          </media-slider-preview>
        </media-time-slider>
      </media-controls-group>

      <media-controls-group
        class="-mt-0.5 flex w-full items-center px-2 pb-2 bg-gradient-to-t from-black to-transparent"
      >
        <!-- -10s Button -->
        <media-tooltip>
          <media-tooltip-trigger>
            <media-seek-button
              class="relative inline-flex h-10 w-10 cursor-pointer items-center justify-center rounded-md outline-none ring-inset ring-sky-400 hover:bg-white/20 aria-hidden:hidden data-[focus]:ring-4"
              seconds="-10"
            >
              <media-icon
                type="seek-backward-10"
                class="h-8 w-8"
              ></media-icon>
            </media-seek-button>
          </media-tooltip-trigger>
          <media-tooltip-content
            class="animate-out fade-out slide-out-to-bottom-2 data-[visible]:animate-in data-[visible]:fade-in data-[visible]:slide-in-from-bottom-4 z-10 rounded-sm bg-black/90 px-2 py-0.5 text-sm font-medium text-white"
            placement="top start"
            offset="30"
          >
            <span>-10s</span>
          </media-tooltip-content>
        </media-tooltip>

        <!-- Play Button -->
        <media-tooltip>
          <media-tooltip-trigger>
            <media-play-button
              class="ring-media-focus relative inline-flex h-10 w-10 cursor-pointer items-center justify-center rounded-md outline-none ring-inset hover:bg-white/20 data-[focus]:ring-4"
            >
              <media-icon
                class="media-paused:block hidden h-8 w-8"
                type="play"
              ></media-icon>
              <media-icon
                class="media-paused:hidden h-8 w-8"
                type="pause"
              ></media-icon>
            </media-play-button>
          </media-tooltip-trigger>
          <media-tooltip-content
            class="animate-out fade-out slide-out-to-bottom-2 data-[visible]:animate-in data-[visible]:fade-in data-[visible]:slide-in-from-bottom-4 z-10 rounded-sm bg-black/90 px-2 py-0.5 text-sm font-medium text-white"
            placement="top start"
            offset="30"
          >
            <span class="media-paused:block hidden">Play</span>
            <span class="media-paused:hidden">Pause</span>
          </media-tooltip-content>
        </media-tooltip>

        <!-- +10s Button -->
        <media-tooltip>
          <media-tooltip-trigger>
            <media-seek-button
              class="relative inline-flex h-10 w-10 cursor-pointer items-center justify-center rounded-md outline-none ring-inset ring-sky-400 hover:bg-white/20 aria-hidden:hidden data-[focus]:ring-4"
              seconds="10"
            >
              <media-icon
                type="seek-forward-10"
                class="h-8 w-8"
                data-testid="button-seek-forward"
              ></media-icon>
            </media-seek-button>
          </media-tooltip-trigger>
          <media-tooltip-content
            class="animate-out fade-out slide-out-to-bottom-2 data-[visible]:animate-in data-[visible]:fade-in data-[visible]:slide-in-from-bottom-4 z-10 rounded-sm bg-black/90 px-2 py-0.5 text-sm font-medium text-white"
            placement="top start"
            offset="30"
          >
            <span>+10s</span>
          </media-tooltip-content>
        </media-tooltip>

        <!-- Mute Button -->
        <media-tooltip>
          <media-tooltip-trigger>
            <media-mute-button
              class="ring-media-focus group relative -mr-1.5 inline-flex h-10 w-10 cursor-pointer items-center justify-center rounded-md outline-none ring-inset hover:bg-white/20 data-[focus]:ring-4"
            >
              <media-icon
                class="hidden h-8 w-8 group-data-[state='muted']:block"
                type="mute"
              ></media-icon>
              <media-icon
                class="hidden h-8 w-8 group-data-[state='low']:block"
                type="volume-low"
              ></media-icon>
              <media-icon
                class="hidden h-8 w-8 group-data-[state='high']:block"
                type="volume-high"
              ></media-icon>
            </media-mute-button>
          </media-tooltip-trigger>
          <media-tooltip-content
            class="animate-out fade-out slide-out-to-bottom-2 data-[visible]:animate-in data-[visible]:fade-in data-[visible]:slide-in-from-bottom-4 z-10 rounded-sm bg-black/90 px-2 py-0.5 text-sm font-medium text-white"
            placement="top"
            offset="30"
          >
            <span class="media-muted:hidden">Ton aus</span>
            <span class="media-muted:block hidden">Ton an</span>
          </media-tooltip-content>
        </media-tooltip>

        <!-- Volume Slider -->
        <media-volume-slider
          class="group relative mx-[7.5px] inline-flex h-10 w-full max-w-[80px] cursor-pointer touch-none select-none items-center outline-none"
        >
          <!-- Slider Track -->
          <div
            class="ring-media-focus relative z-0 h-[5px] w-full rounded-sm bg-white/30 group-data-[focus]:ring-[3px]"
          >
            <div class="bg-media-brand absolute h-full w-[var(--slider-fill)] rounded-sm will-change-[width]"></div>
          </div>
          <!-- Slider Thumb -->
          <div
            class="absolute left-[var(--slider-fill)] top-1/2 z-20 h-[15px] w-[15px] -translate-x-1/2 -translate-y-1/2 rounded-full border border-[#cacaca] bg-white opacity-0 ring-white/40 transition-opacity will-change-[left] group-data-[active]:opacity-100 group-data-[dragging]:ring-4"
          ></div>
          <media-slider-preview
            class="pointer-events-none opacity-0 transition-opacity duration-200 data-[visible]:opacity-100"
            no-clamp
            offset="30"
          >
            <media-slider-value
              class="animate-out fade-out slide-out-to-bottom-2 data-[visible]:animate-in data-[visible]:fade-in data-[visible]:slide-in-from-bottom-4 z-10 rounded-sm bg-black/90 px-2 py-0.5 text-sm font-medium text-white"
            ></media-slider-value>
          </media-slider-preview>
        </media-volume-slider>

        <!-- Time Group -->
        <div class="ml-1.5 flex items-center text-sm font-medium">
          <media-time type="current"></media-time>
          <div class="mx-1 text-white/80">/</div>
          <media-time type="duration"></media-time>
        </div>

        <span
          class="inline-block flex-1 overflow-hidden text-ellipsis whitespace-nowrap px-2 text-sm font-medium text-white/70"
        >
          <span class="mr-1">| {{ title() }}</span>
          <media-chapter-title></media-chapter-title>
        </span>

        <!-- Download Button -->
        @if (video.downloadUrl) {
          <media-tooltip>
            <media-tooltip-trigger>
              <a
                class="ring-media-focus group relative mr-0.5 inline-flex h-10 w-10 cursor-pointer items-center justify-center rounded-md outline-none ring-inset hover:bg-white/20 data-[focus]:ring-4"
                [href]="video.downloadUrl"
                target="_blank"
              >
                <media-icon
                  class="h-8 w-8"
                  type="download"
                ></media-icon>
              </a>
            </media-tooltip-trigger>
            <media-tooltip-content
              class="animate-out fade-out slide-out-to-bottom-2 data-[visible]:animate-in data-[visible]:fade-in data-[visible]:slide-in-from-bottom-4 z-10 rounded-sm bg-black/90 px-2 py-0.5 text-sm font-medium text-white"
              placement="top"
              offset="30"
            >
              <span>Download</span>
            </media-tooltip-content>
          </media-tooltip>
        }
        <!-- Caption Button -->
        @if (video.captions && video.captions.length) {
          <media-tooltip>
            <media-tooltip-trigger>
              <media-caption-button
                class="ring-media-focus group relative mr-0.5 inline-flex h-10 w-10 cursor-pointer items-center justify-center rounded-md outline-none ring-inset hover:bg-white/20 data-[focus]:ring-4"
              >
                <media-icon
                  class="media-captions:block hidden h-8 w-8"
                  type="closed-captions-on"
                ></media-icon>
                <media-icon
                  class="media-captions:hidden h-8 w-8"
                  type="closed-captions"
                ></media-icon>
              </media-caption-button>
            </media-tooltip-trigger>
            <media-tooltip-content
              class="animate-out fade-out slide-out-to-bottom-2 data-[visible]:animate-in data-[visible]:fade-in data-[visible]:slide-in-from-bottom-4 z-10 rounded-sm bg-black/90 px-2 py-0.5 text-sm font-medium text-white"
              placement="top"
              offset="30"
            >
              <span class="media-captions:block hidden">Untertitel aus</span>
              <span class="media-captions:hidden">Untertitel an</span>
            </media-tooltip-content>
          </media-tooltip>
        }
        <!-- Settings Menu -->
        <media-menu class="group">
          <!-- Settings Menu Button -->
          <media-tooltip>
            <media-tooltip-trigger>
              <media-menu-button
                class="ring-media-focus relative mr-0.5 inline-flex h-10 w-10 cursor-pointer items-center justify-center rounded-md outline-none ring-inset hover:bg-white/20 aria-hidden:hidden data-[focus]:ring-4"
              >
                <media-icon
                  class="h-8 w-8 transform transition-transform duration-200 ease-out group-data-[open]:rotate-90"
                  type="settings"
                ></media-icon>
              </media-menu-button>
            </media-tooltip-trigger>
            <media-tooltip-content
              class="animate-out fade-out slide-out-to-bottom-2 data-[visible]:animate-in data-[visible]:fade-in data-[visible]:slide-in-from-bottom-4 z-10 rounded-sm bg-black/90 px-2 py-0.5 text-sm font-medium text-white group-data-[open]:hidden"
              placement="top"
              offset="30"
            >
              Einstellungen
            </media-tooltip-content>
          </media-tooltip>
          <!-- Settings Menu Items -->
          <media-menu-items
            class="animate-out fade-out slide-out-to-bottom-2 data-[open]:animate-in data-[open]:fade-in data-[open]:slide-in-from-bottom-4 flex h-[var(--menu-height)] max-h-[400px] min-w-[260px] flex-col overflow-y-auto overscroll-y-contain rounded-md border border-white/10 bg-black/95 p-2.5 text-[15px] font-medium outline-none backdrop-blur-sm transition-[height] duration-300 will-change-[height] data-[transition]:overflow-hidden"
            offset="30"
            placement="top end"
          >
            <!-- Caption Submenu -->
            @if (video.captions && video.captions.length) {
              <media-menu>
                <!-- Caption Submenu Button -->
                <media-menu-button
                  class="ring-media-focus parent left-0 z-10 flex w-full cursor-pointer select-none items-center justify-start rounded-sm bg-black/60 p-2.5 outline-none ring-inset aria-disabled:hidden aria-hidden:hidden data-[open]:sticky data-[open]:-top-2.5 data-[hocus]:bg-white/10 data-[focus]:ring-[3px]"
                >
                  <!-- Close Icon -->
                  <media-icon
                    class="parent-data-[open]:block -ml-0.5 mr-1.5 hidden h-[18px] w-[18px]"
                    type="chevron-left"
                  ></media-icon>
                  <!-- Icon -->
                  <media-icon
                    class="parent-data-[open]:hidden mr-1.5 h-5 w-5"
                    type="closed-captions"
                  ></media-icon>
                  <!-- Label -->
                  <span class="mr-1.5">Untertitel</span>
                  <!-- Hint -->
                  <span
                    class="ml-auto text-sm text-white/50"
                    data-part="hint"
                  ></span>
                  <!-- Open Icon -->
                  <media-icon
                    class="parent-data-[open]:hidden ml-0.5 h-[18px] w-[18px] text-sm text-white/50"
                    type="chevron-right"
                  ></media-icon>
                </media-menu-button>
                <!-- Caption Submenu Items -->
                <media-menu-items
                  class="hidden w-full flex-col items-start justify-center outline-none data-[keyboard]:mt-[3px] data-[open]:inline-block"
                >
                  <media-captions-radio-group
                    off-label="Aus"
                    class="flex w-full flex-col"
                  >
                    <template>
                      <media-radio
                        class="ring-media-focus group relative flex w-full cursor-pointer select-none items-center justify-start rounded-sm p-2.5 outline-none data-[hocus]:bg-white/10 data-[focus]:ring-[3px]"
                      >
                        <media-icon
                          class="h-4 w-4 group-data-[checked]:hidden"
                          type="radio-button"
                        ></media-icon>
                        <media-icon
                          class="text-media-brand hidden h-4 w-4 group-data-[checked]:block"
                          type="radio-button-selected"
                        ></media-icon>
                        <span
                          class="ml-2"
                          data-part="label"
                        ></span>
                      </media-radio>
                    </template>
                  </media-captions-radio-group>
                </media-menu-items>
              </media-menu>
            }
            <!-- Quality Submenu -->
            <media-menu>
              <!-- Quality Submenu Button -->
              <media-menu-button
                class="ring-media-focus parent left-0 z-10 flex w-full cursor-pointer select-none items-center justify-start rounded-sm bg-black/60 p-2.5 outline-none ring-inset aria-disabled:hidden aria-hidden:hidden data-[open]:sticky data-[open]:-top-2.5 data-[hocus]:bg-white/10 data-[focus]:ring-[3px]"
              >
                <!-- Close Icon -->
                <media-icon
                  class="parent-data-[open]:block -ml-0.5 mr-1.5 hidden h-[18px] w-[18px]"
                  type="chevron-left"
                ></media-icon>
                <!-- Icon -->
                <media-icon
                  class="parent-data-[open]:hidden mr-1.5 h-5 w-5"
                  type="settings-menu"
                ></media-icon>
                <!-- Label -->
                <span class="mr-1.5">Qualität</span>
                <!-- Hint -->
                <span
                  class="ml-auto text-sm text-white/50"
                  data-part="hint"
                ></span>
                <!-- Open Icon -->
                <media-icon
                  class="parent-data-[open]:hidden ml-0.5 h-[18px] w-[18px] text-sm text-white/50"
                  type="chevron-right"
                ></media-icon>
              </media-menu-button>
              <!-- Quality Submenu Items -->
              <media-menu-items
                class="hidden w-full flex-col items-start justify-center outline-none data-[keyboard]:mt-[3px] data-[open]:inline-block"
              >
                <media-quality-radio-group
                  class="flex w-full flex-col"
                  auto-label="Auto"
                >
                  <template>
                    <media-radio
                      class="group relative flex w-full cursor-pointer select-none items-center justify-start rounded-sm p-2.5 outline-none ring-sky-400 data-[hocus]:bg-white/10 data-[focus]:ring-[3px]"
                    >
                      <media-icon
                        class="h-4 w-4 group-data-[checked]:hidden"
                        type="radio-button"
                      ></media-icon>
                      <media-icon
                        class="text-media-brand hidden h-4 w-4 group-data-[checked]:block"
                        type="radio-button-selected"
                      ></media-icon>
                      <span
                        class="ml-2"
                        data-part="label"
                      ></span>
                      <span
                        class="ml-auto text-[13px] text-gray-300"
                        data-part="bitrate"
                      ></span>
                    </media-radio>
                  </template>
                </media-quality-radio-group>
              </media-menu-items>
            </media-menu>

            <!-- Speed Submenu -->
            <media-menu>
              <!-- Speed Submenu Button -->
              <media-menu-button
                class="ring-media-focus parent left-0 z-10 flex w-full cursor-pointer select-none items-center justify-start rounded-sm bg-black/60 p-2.5 outline-none ring-inset aria-disabled:hidden aria-hidden:hidden data-[open]:sticky data-[open]:-top-2.5 data-[hocus]:bg-white/10 data-[focus]:ring-[3px]"
              >
                <!-- Close Icon -->
                <media-icon
                  class="parent-data-[open]:block -ml-0.5 mr-1.5 hidden h-[18px] w-[18px]"
                  type="chevron-left"
                ></media-icon>
                <!-- Icon -->
                <media-icon
                  class="parent-data-[open]:hidden mr-1.5 h-5 w-5"
                  type="odometer"
                ></media-icon>
                <!-- Label -->
                <span class="mr-1.5">Geschwindigkeit</span>
                <!-- Hint -->
                <span
                  class="ml-auto text-sm text-white/50"
                  data-part="hint"
                ></span>
                <!-- Open Icon -->
                <media-icon
                  class="parent-data-[open]:hidden ml-0.5 h-[18px] w-[18px] text-sm text-white/50"
                  type="chevron-right"
                ></media-icon>
              </media-menu-button>
              <!-- Speed Submenu Items -->
              <media-menu-items
                class="hidden w-full flex-col items-start justify-center outline-none data-[keyboard]:mt-[3px] data-[open]:inline-block"
              >
                <media-speed-radio-group
                  class="flex w-full flex-col"
                  normal-label="Normal"
                >
                  <template>
                    <media-radio
                      class="group relative flex w-full cursor-pointer select-none items-center justify-start rounded-sm p-2.5 outline-none ring-sky-400 data-[hocus]:bg-white/10 data-[focus]:ring-[3px]"
                    >
                      <media-icon
                        class="h-4 w-4 group-data-[checked]:hidden"
                        type="radio-button"
                      ></media-icon>
                      <media-icon
                        class="text-media-brand hidden h-4 w-4 group-data-[checked]:block"
                        type="radio-button-selected"
                      ></media-icon>
                      <span
                        class="ml-2"
                        data-part="label"
                      ></span>
                    </media-radio>
                  </template>
                </media-speed-radio-group>
              </media-menu-items>
            </media-menu>
          </media-menu-items>
        </media-menu>

        <!-- PIP Button -->
        <media-tooltip>
          <media-tooltip-trigger>
            <media-pip-button
              class="ring-media-focus group relative mr-0.5 inline-flex h-10 w-10 cursor-pointer items-center justify-center rounded-md outline-none ring-inset hover:bg-white/20 data-[focus]:ring-4"
            >
              <media-icon
                class="media-pip:hidden h-8 w-8"
                type="picture-in-picture"
              ></media-icon>
              <media-icon
                class="media-pip:block hidden h-8 w-8"
                type="picture-in-picture-exit"
              ></media-icon>
            </media-pip-button>
          </media-tooltip-trigger>
          <media-tooltip-content
            class="animate-out fade-out slide-out-to-bottom-2 data-[visible]:animate-in data-[visible]:fade-in data-[visible]:slide-in-from-bottom-4 z-10 rounded-sm bg-black/90 px-2 py-0.5 text-sm font-medium text-white"
            placement="top"
            offset="30"
          >
            <span class="media-pip:hidden">Bild-in-Bild an</span>
            <span class="media-pip:block hidden">Bild-in-Bild aus</span>
          </media-tooltip-content>
        </media-tooltip>

        <!-- Fullscreen Button -->
        <media-tooltip>
          <media-tooltip-trigger>
            <media-fullscreen-button
              class="ring-media-focus group relative inline-flex h-10 w-10 cursor-pointer items-center justify-center rounded-md outline-none ring-inset hover:bg-white/20 data-[focus]:ring-4"
            >
              <media-icon
                class="media-fullscreen:hidden h-8 w-8"
                type="fullscreen"
              ></media-icon>
              <media-icon
                class="media-fullscreen:block hidden h-8 w-8"
                type="fullscreen-exit"
              ></media-icon>
            </media-fullscreen-button>
          </media-tooltip-trigger>
          <media-tooltip-content
            class="animate-out fade-out slide-out-to-bottom-2 data-[visible]:animate-in data-[visible]:fade-in data-[visible]:slide-in-from-bottom-4 z-10 rounded-sm bg-black/90 px-2 py-0.5 text-sm font-medium text-white"
            placement="top end"
            offset="30"
          >
            <span class="media-fullscreen:hidden">Vollbild an</span>
            <span class="media-fullscreen:block hidden">Vollbild aus</span>
          </media-tooltip-content>
        </media-tooltip>
      </media-controls-group>
    </media-controls>
    <div class="pointer-events-none absolute inset-0 z-50 flex h-full w-full items-center justify-center">
      <media-spinner
        data-testid="media-spinner"
        class="text-primary opacity-0 transition-opacity duration-200 ease-linear media-buffering:animate-spin media-buffering:opacity-100 [&_[data-part='track']]:text-white [&_[data-part='track']]:opacity-30"
        size="96"
        track-width="8"
      ></media-spinner>
    </div>
    @if (endingSpinnerVisible) {
      <mat-progress-spinner
        [value]="endingSpinner$ | async"
        class="!absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 z-10"
        color="primary"
        mode="determinate"
        [strokeWidth]="6"
        data-testid="ending-spinner"
      ></mat-progress-spinner>
    }
  </media-player>
}
