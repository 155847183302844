import { AbstractControl, ValidationErrors } from '@angular/forms';

export class TextValidator {
  static wordCount(wordsNeeded: number): ValidationErrors | null {
    return (control: AbstractControl) => (control.value.match(/\S+/g)?.length >= wordsNeeded ? null : ['wordsNeeded']);
  }

  static reject(rejectValuePattern: string) {
    const pattern = new RegExp(rejectValuePattern);

    return (control: AbstractControl) => (pattern.test(control.value) ? { reject: true } : null);
  }
}
