<ng-container *ngIf="dialogData$$ | async as dialogData; else loading">
  <div
    class="dialog-title-container"
    data-test="dialog-status"
    mat-dialog-title
  >
    <div class="dialog-icon-container">
      <mat-icon
        *ngIf="dialogData.data === LoadingDialogState.Success"
        class="icon icon-success"
        data-test="loading-dialog-success"
      >
        check_circle_outline
      </mat-icon>
      <mat-icon
        *ngIf="dialogData.data === LoadingDialogState.Error"
        class="icon icon-error"
        data-test="loading-dialog-error"
      >
        error_outline
      </mat-icon>
    </div>
    <h4 *ngIf="dialogData.title">{{ dialogData.title }}</h4>
  </div>
  <div
    data-testid="dialog-content"
    [innerHTML]="dialogData.content"
    class="content"
    mat-dialog-content
  ></div>
  <div
    class="loading-dialog-actions"
    mat-dialog-actions
  >
    <button
      data-testid="save-loading-dialog"
      [mat-dialog-close]="true"
      appearance="primary"
      cdkFocusInitial
      wvButton
    >
      {{ 'control.okButton' | transloco }}
    </button>
  </div>
</ng-container>

<ng-template #loading>
  <mat-spinner data-test="dialog-loader" class="dialog-loader"
  data-testid="dialog-loader"></mat-spinner>
</ng-template>
