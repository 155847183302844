import { Injectable } from '@angular/core';
import platform from '../../../../node_modules/platform';

interface BrowserInfo {
  browserName: string;
  browserVersion: string;
  operatingSystem: string;
  screenWidth: number;
  screenHeight: number;
  nativeScreenWidth: number;
  nativeScreenHeight: number;
  browserLanguage: string;
}

@Injectable({ providedIn: 'root' })
export class BrowserInfoService {
  getBrowserInfo(): BrowserInfo {
    return {
      browserLanguage: navigator.language,
      browserName: platform.name,
      browserVersion: platform.version,
      nativeScreenHeight: window.screen.height * window.devicePixelRatio,
      nativeScreenWidth: window.screen.width * window.devicePixelRatio,
      operatingSystem: platform.os,
      screenHeight: window.screen.availHeight,
      screenWidth: window.screen.availWidth
    };
  }
}
