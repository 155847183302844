<mat-form-field
  (click)="openOverlay()"
  appearance="outline"
  [class.cursor-pointer]="!disabled()"
  class="w-72"
>
  <mat-label>{{ label() }}</mat-label>
  <input
    #trigger="cdkOverlayOrigin"
    [readonly]="true"
    [disabled]="disabled()"
    [value]="formattedRange()"
    cdkOverlayOrigin
    class="cursor-pointer"
    matInput
  />
  <mat-hint>{{ hint() }}</mat-hint>
  <button
    aria-label="Clear"
    mat-icon-button
    matSuffix
    [disabled]="disabled()"
  >
    <mat-icon>date_range</mat-icon>
  </button>
</mat-form-field>

<ng-template
  (backdropClick)="closeOverlay()"
  [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayOffsetY]="20"
  [cdkConnectedOverlayOffsetX]="-12"
  [cdkConnectedOverlayOpen]="isOverlayOpen()"
  [cdkConnectedOverlayOrigin]="trigger"
  cdkConnectedOverlay
  cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
>
  <div class="max-w-[520px] border-1 border-primary drop-shadow-xl bg-surface p-4">
    <div class="flex flex-col gap-8">
      <div class="grid grid-cols-2 gap-4">
        @for (preset of presets(); track preset.label) {
          <button
            class="text-left cursor-pointer hover:text-secondary text-sm font-semibold"
            (click)="setPreset(preset)"
          >
            {{ preset.label }}
          </button>
        }
      </div>
      <div class="flex flex-col gap-4">
        <p class="text-sm font-semibold p-0">Oder beliebigen Zeitraum wählen:</p>
        <div
          [formGroup]="rangeFormGroup"
          class="flex gap-4 items-center"
        >
          <mat-form-field>
            <mat-label>Von</mat-label>
            <input
              (click)="pickerFrom.open()"
              [matDatepicker]="pickerFrom"
              [max]="max()"
              [readonly]="true"
              class="cursor-pointer"
              formControlName="from"
              matInput
            />
            <mat-datepicker-toggle
              [for]="pickerFrom"
              matSuffix
            ></mat-datepicker-toggle>
            <mat-datepicker #pickerFrom></mat-datepicker>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Bis</mat-label>
            <input
              (click)="pickerTo.open()"
              [matDatepicker]="pickerTo"
              [max]="max()"
              [readonly]="true"
              class="cursor-pointer"
              formControlName="to"
              matInput
            />
            <mat-datepicker-toggle
              [for]="pickerTo"
              matSuffix
            ></mat-datepicker-toggle>
            <mat-datepicker #pickerTo></mat-datepicker>
          </mat-form-field>
          <button
            (click)="setCustomRange()"
            mat-mini-fab
            color="accent"
            class="!text-on-secondary"
          >
            <mat-icon class="-mt-[3px]">arrow_right_alt</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
