<div *ngIf="passwordPolicy.requireDigit" class="policy-row">
  <mat-icon *ngIf="passwordContainsDigit" class="icon-success">check_circle_outline</mat-icon>
  <mat-icon *ngIf="!passwordContainsDigit" class="icon-error">error_outline</mat-icon>
  <span>{{ 'passwordPolicy.requireDigit' | transloco }}</span>
</div>
<div *ngIf="passwordPolicy.requireLowercase" class="policy-row">
  <mat-icon *ngIf="passwordContainsLowercase" class="icon-success">check_circle_outline</mat-icon>
  <mat-icon *ngIf="!passwordContainsLowercase" class="icon-error">error_outline</mat-icon>
  <span>{{ 'passwordPolicy.requireLowercase' | transloco }}</span>
</div>
<div *ngIf="passwordPolicy.requireNonAlphanumeric" class="policy-row">
  <mat-icon *ngIf="passwordContainsSpecialCharacter" class="icon-success">check_circle_outline</mat-icon>
  <mat-icon *ngIf="!passwordContainsSpecialCharacter" class="icon-error">error_outline</mat-icon>
  <span>{{ 'passwordPolicy.requireNonAlphanumeric' | transloco }}</span>
</div>
<div *ngIf="passwordPolicy.requireUppercase" class="policy-row">
  <mat-icon *ngIf="passwordContainsUppercase" class="icon-success">check_circle_outline</mat-icon>
  <mat-icon *ngIf="!passwordContainsUppercase" class="icon-error">error_outline</mat-icon>
  <span>{{ 'passwordPolicy.requireUppercase' | transloco }}</span>
</div>
<div class="policy-row">
  <mat-icon *ngIf="passwordHasRequiredMinimalLength" class="icon-success">check_circle_outline</mat-icon>
  <mat-icon *ngIf="!passwordHasRequiredMinimalLength" class="icon-error">error_outline</mat-icon>
  <span>{{ 'passwordPolicy.requiredLength' | transloco }}: {{ passwordPolicy.requiredLength }}</span>
</div>
<div class="policy-row">
  <mat-icon *ngIf="passwordHasRequiredCountOfUniqueChars" class="icon-success">check_circle_outline</mat-icon>
  <mat-icon *ngIf="!passwordHasRequiredCountOfUniqueChars" class="icon-error">error_outline</mat-icon>
  <span>{{ 'passwordPolicy.requiredUniqueChars' | transloco }}: {{ passwordPolicy.requiredUniqueChars }}</span>
</div>
