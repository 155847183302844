import { Component, Input } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-info-video-layout',
  templateUrl: './info-video-layout.component.html',
  styleUrls: ['./info-video-layout.component.scss']
})
export class InfoVideoLayoutComponent {
  @Input() title: string;
  @Input() description: string;
  @Input() subtitle: string;
  environment = environment;
}
