import { Component } from '@angular/core';
import { IdentityService } from '../../identity/identity.service';

@Component({
  selector: 'app-navigation-layout',
  templateUrl: './navigation-layout.component.html',
  styleUrls: ['./navigation-layout.component.scss']
})
export class NavigationLayoutComponent {
  constructor(public identityService: IdentityService) {}
}
