export const environment = {
  apiRoutes: {
    login: '/login',
    status: '/status',
    users: '/Users',
    usersUserData: '/Users/user-data',
    usersUpdateGender: '/Users/update-gender',
    usersUpdateFirstName: '/Users/update-firstName',
    usersUpdateLastName: '/Users/update-lastName',
    usersUpdateEmailRequest: '/Users/update-email-request',
    usersUpdateDepartment: '/Users/update-department',
    usersUpdatePassword: '/Users/update-password',
    usersUpdateEmail: '/Users/update-email',
    inviteUserFile: '/InviteUserFile',
    inviteUserList: '/InviteUserList',
    userInvitations: '/UserInvitations',
    whitelistedAccesses: '/WhitelistedAccesses',
    insurances: '/Insurances',
    rolesProductAdmin: 'Roles/product-admin',
    registerUser: '/RegisterUser',
    requestAccess: '/RequestAccess',
    updateToken: '/update-token',
    resetPasswordRequest: '/reset-password-request',
    resetPassword: '/reset-password',
    passwordPolicy: '/password-policy',
    companies: '/Companies',
    tempMails: '/TempMails',
    courses: '/Courses',
    courseOverviewCourses: '/CourseOverview',
    coursePlayerCourses: '/Player',
    coursePlayerWatchedVideos: '/WatchedVideos',
    certificates: '/Certificates',
    phishingMails: '/PhishingMails',
    phishingMailsCompanies: '/PhishingMails/Companies',
    courseCms: '/CourseCMS',
    courseCmsUpload: '/CourseCMS/upload',
    toolkitPasswordChecks: '/Toolkit/PasswordChecks',
    newsletters: '/Newsletters',
    feedbacks: '/Feedbacks',
    ransomClauses: '/RansomClauses',
    administration: '/Admin',
    companySubscriptions: '/CompanySubscriptions'
  },
  config: {
    reCaptcha: {
      theme: 'light'
    },
    cookieConsent: {
      palette: {
        popup: {
          background: 'var(--background-cookie-consent)',
          text: 'var(--color-cookie-consent)'
        }
      },
      theme: 'classic',
      type: 'info',
      position: 'bottom-left'
    },
    translate: {
      availableLanguages: ['de'],
      defaultLanguage: 'de',
      fallbackLanguage: 'de',
      renderOnLanguageChange: true,
      useBrowserLanguage: false
    }
  },
  assets: {
    logo: 'https://stcpmediaprod.blob.core.windows.net/images/co-IT_Logo.svg',
    logoDark: '/assets/images/coit-logo-dark.svg',
    hero: 'https://stcpmediaprod.blob.core.windows.net/images/keyvisual.jpeg',
    wvLogo: 'https://stcpmediaprod.blob.core.windows.net/images/wv-logo-slogan.svg',
    ransomClause: {
      src: 'https://stcpmediaprod.blob.core.windows.net/documents/Erweiterte_Erpressungsklausel.pdf'
    },
    portalOverview: {
      courseOverview: 'https://stcpmediaprod.blob.core.windows.net/images/course-overview.jpeg',
      userManagement: 'https://stcpmediaprod.blob.core.windows.net/images/user-management.jpeg',
      phishingSimulation: 'https://stcpmediaprod.blob.core.windows.net/images/phishing-simulation.jpeg',
      toolkit: 'https://stcpmediaprod.blob.core.windows.net/images/toolkit.jpeg',
      administration: 'https://stcpmediaprod.blob.core.windows.net/images/administration.jpeg',
      help: 'https://stcpmediaprod.blob.core.windows.net/images/help.jpeg'
    }
  },
  resources: {
    helpAdminConfiguration: 'https://e.co-it.eu/cyberportal/mail-freigabe',
    helpVideoAgencies:
      'https://stcpmediaprod.blob.core.windows.net/video-fuer-agentur/Video-F%C3%BCr-Agenturen_FullHD.m3u8',
    helpVideoAgenciesSubtitles:
      'https://stcpmediaprod.blob.core.windows.net/video-fuer-agentur/Video-F%C3%BCr-Agenturen_FullHD_transskript.vtt',
    helpVideoAccountantRegistration:
      'https://stcpmediaprod.blob.core.windows.net/registrierung-cyberverantwortlicher/Registrierung_als_Cyberverantwortlicher.m3u8',
    helpVideoAccountantRegistrationSubtitles:
      'https://stcpmediaprod.blob.core.windows.net/registrierung-cyberverantwortlicher/Registrierung_als_Cyberverantwortlicher_transskript.vtt',
    helpVideoUserRegistration:
      'https://stcpmediaprod.blob.core.windows.net/registrierung-anwender/Registrierung-als-Anwender.m3u8',
    helpVideoUserRegistrationSubtitles:
      'https://stcpmediaprod.blob.core.windows.net/registrierung-anwender/Registrierung-als-Anwender_transskript.vtt',
    helpVideoUserFeatures:
      'https://stcpmediaprod.blob.core.windows.net/funktionalitaeten-anwender-v2/Cyber_Portal-Funktionen_Benutzer.m3u8',
    helpVideoUserFeaturesSubtitles:
      'https://stcpmediaprod.blob.core.windows.net/funktionalitaeten-anwender-v2/Cyber_Portal-Funktionen_Benutzer_transskript.vtt',
    helpVideoAccountantFeatures:
      'https://stcpmediaprod.blob.core.windows.net/funktionalitaeten-cyberverantwortlicher-v2/Cyber_Portal-Funktionen_CV.m3u8',
    helpVideoAccountantFeaturesSubtitles:
      'https://stcpmediaprod.blob.core.windows.net/funktionalitaeten-cyberverantwortlicher-v2/Cyber_Portal-Funktionen_CV_transskript.vtt',
    helpVideoPricedPackagesFeatures:
        'https://stcpmediaprod.blob.core.windows.net/funktionalitaeten-kostenpflichtige-pakete/Funktionalit%C3%A4ten_Kostenpflichtige_Pakete.m3u8',
    helpVideoPricedPackagesFeaturesSubtitles:
        'https://stcpmediaprod.blob.core.windows.net/funktionalitaeten-kostenpflichtige-pakete/Funktionalit%C3%A4ten_Kostenpflichtige_Pakete_transskript.vtt',
    ransomClause:
      'https://stcpmediaprod.blob.core.windows.net/digitales-praeventions-gespraech/DigitalesPr%C3%A4ventionsgespr%C3%A4ch-geschnitten.m3u8',
    ransomClauseSubtitles:
      'https://stcpmediaprod.blob.core.windows.net/digitales-praeventions-gespraech/DigitalesPr%C3%A4ventionsgespr%C3%A4ch-geschnitten_transskript.vtt'
  },
  urls: {
    generalTermsAndConditions: 'https://e.co-it.eu/agb',
    privacy: 'https://e.co-it.eu/cyber-portal/datenschutz',
    scopeOfServices: 'https://e.co-it.eu/cyber-portal/leistungsumfang',
    termsOfUse: 'https://e.co-it.eu/cyber-portal/nutzungsbedingungen',
    imprint: 'https://e.co-it.eu/impressum',
    author: 'https://co-IT.eu',
    packages: 'https://co-it.eu/cyber/cyber-portal'
  },
  contact: {
    phone: '+49721935163010'
  }
};
