import { AbstractControl, ValidatorFn } from '@angular/forms';

export class FileInputValidator {
  static allowedFileTypes(allowedFileTypes: string[]): ValidatorFn {
    return (control: AbstractControl): { [key: string]: unknown } | null => {
      if (control.value === '') {
        return null;
      }
      const file = control.value?._files[0] as File;

      if (!allowedFileTypes.includes(file?.type)) {
        return { wrongFileType: { value: file } };
      }

      return null;
    };
  }
}
