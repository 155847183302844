import {LocalMediaStorage, Src} from "vidstack";

export class CustomLocalMediaStorage extends LocalMediaStorage {
  constructor(playerId: string) {
    super();
    this.playerId = playerId;
  }

  override async getTime() {
    return null;
  }

  override async setTime() {
    //do nothing
  }

  override onChange(src: Src, mediaId: string | null) {
    super.onChange(src, mediaId, this.playerId);
  }
}
