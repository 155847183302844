import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'duration'
})
export class DurationPipe implements PipeTransform {
  transform(seconds: number): string {
    const pipe = new DatePipe('de');

    const m = pipe.transform(seconds * 1000, 'm');
    const s = pipe.transform(seconds * 1000, 's');

    if (seconds < 60) {
      return `${s} s`;
    }

    return `${m} m ${s} s`;
  }
}
