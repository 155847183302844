import {AbstractControl, ValidatorFn} from '@angular/forms';

export class CustomValidator {
  static requireMatch(options: unknown[]): ValidatorFn {
    return (control: AbstractControl) => {
      const selection: unknown[] = control.value;

      if (options && options.indexOf(selection) < 0) {
        return {requireMatch: true};
      }

      return null;
    };
  }

  static url(allowEmpty = true): ValidatorFn {
    return (control: AbstractControl) => {
      if(allowEmpty && control.value === '')
        return null;

      try {
        new URL(control.value)
      } catch {
        return { url: true };
      }

      return null;
    };
  }
}
