<form
  *ngIf="lesson$ | async as lesson"
  [formGroup]="formGroup"
>
  <app-back-layout
    class="-translate-x-12"
    [backUrl]="moduleUrl()"
    [title]="lesson.title"
  ></app-back-layout>

  <span>
    <small>{{ 'cms.lesson.id' | transloco }}: {{ lesson.id }}</small>
  </span>
  <mat-form-field>
    <input
      [placeholder]="'cms.lesson.title' | transloco"
      formControlName="title"
      matInput
    />
  </mat-form-field>
  <mat-form-field>
    <input
      [placeholder]="'cms.lesson.duration' | transloco"
      formControlName="duration"
      matInput
      type="number"
    />
  </mat-form-field>
  <mat-form-field>
    <input
      [placeholder]="'cms.lesson.streamUrl' | transloco"
      formControlName="streamUrl"
      matInput
    />
  </mat-form-field>
  <div
    class="resources"
    formArrayName="resources"
  >
    <h3>{{ 'cms.resource.heading' | transloco }}</h3>
    <button
      (click)="addResource()"
      color="primary"
      mat-raised-button
    >
      {{ 'cms.addButton' | transloco }}
    </button>
    <div
      *ngFor="let resource of formGroup.controls['resources']['controls']; index as i"
      class="mt-4"
    >
      <mat-card
        [formGroupName]="i"
        class="resource"
      >
        <mat-form-field>
          <input
            [placeholder]="'cms.resource.name' | transloco"
            formControlName="name"
            matInput
          />
        </mat-form-field>
        <mat-form-field>
          <input
            [placeholder]="'cms.resource.url' | transloco"
            formControlName="url"
            matInput
          />
        </mat-form-field>
        <mat-form-field>
          <input
            [placeholder]="'cms.resource.description' | transloco"
            formControlName="description"
            matInput
          />
        </mat-form-field>
        <div>
          <button
            (click)="removeResource(i)"
            [matTooltip]="'tooltip.delete' | transloco"
            mat-icon-button
            mat-raised-button
          >
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </mat-card>
    </div>
  </div>
  <div
    class="resources"
    formArrayName="captions"
  >
    <h3>{{ 'cms.subtitle.heading' | transloco }}</h3>
    <button
      (click)="addCaption()"
      color="primary"
      mat-raised-button
    >
      {{ 'cms.addButton' | transloco }}
    </button>
    <div
      *ngFor="let resource of formGroup.controls['captions']['controls']; index as i"
      class="mt-4"
    >
      <mat-card
        [formGroupName]="i"
        class="resource"
      >
        <mat-form-field>
          <input
            [placeholder]="'cms.subtitle.label' | transloco"
            formControlName="label"
            matInput
          />
        </mat-form-field>
        <mat-form-field>
          <input
            [placeholder]="'cms.subtitle.source' | transloco"
            formControlName="source"
            matInput
          />
        </mat-form-field>
        <mat-form-field>
          <input
            [placeholder]="'cms.subtitle.language' | transloco"
            formControlName="language"
            matInput
          />
        </mat-form-field>
        <div>
          <button
            (click)="removeCaption(i)"
            [matTooltip]="'tooltip.delete' | transloco"
            mat-icon-button
          >
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </mat-card>
    </div>
  </div>
</form>
