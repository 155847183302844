import { Component, Input } from '@angular/core';
import { environment } from '@env';

@Component({
  selector: 'awareness-center-wv-hero-banner',
  templateUrl: './wv-hero-banner.component.html',
  styleUrls: ['./wv-hero-banner.component.scss']
})
export class WvHeroBannerComponent {
  @Input() scrollTarget: HTMLElement;

  environment = environment;

  scroll(el: HTMLElement): void {
    el.scrollIntoView({ block: 'start', behavior: 'smooth' });
  }
}
