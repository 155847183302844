import { Component, Input } from '@angular/core';

@Component({
  selector: 'awareness-center-trend',
  templateUrl: './trend.component.html',
  styleUrls: ['./trend.component.scss']
})
export class TrendComponent {
  @Input() title: string;
  @Input() value: number;
  @Input() isPositive: boolean;
}
