<form
  *ngIf="module$ | async as module"
  [formGroup]="formGroup"
>
  <app-back-layout
    class="-translate-x-12"
    [backUrl]="courseUrl()"
    [title]="module.title"
  ></app-back-layout>

  <span>
    <small>{{ 'cms.module.id' | transloco }}: {{ module.id }}</small>
  </span>
  <mat-form-field>
    <input
      [placeholder]="'cms.module.title' | transloco"
      formControlName="title"
      matInput
    />
  </mat-form-field>
  <div>
    <h3>{{ 'cms.lesson.heading' | transloco }}</h3>
    <button
      (click)="addLesson()"
      color="primary"
      mat-raised-button
      type="button"
    >
      {{ 'cms.addButton' | transloco }}
    </button>
    <div
      (cdkDropListDropped)="drop($event)"
      cdkDropList
      class="mt-4"
    >
      <mat-card
        *ngFor="let lesson of module.lessons"
        cdkDrag
      >
        <div
          [routerLink]="['..', 'lessons', lesson.id, 'edit']"
          class="title"
        >
          {{ lesson.title }}
        </div>
        <button
          (click)="removeLesson(lesson.id)"
          [matTooltip]="'tooltip.delete' | transloco"
          mat-icon-button
        >
          <mat-icon>delete</mat-icon>
        </button>
        <app-drag-and-drop-handle cdkDragHandle></app-drag-and-drop-handle>
      </mat-card>
    </div>
  </div>
</form>
