import {ChangeDetectionStrategy, Component, EventEmitter, inject, OnInit, Output} from '@angular/core';
import {CommonModule} from '@angular/common';
import {environment} from "@env";
import {ColibriModule} from "../colibri.module";
import {TranslocoPipe} from "@ngneat/transloco";
import {RecaptchaConsentService} from "./recaptcha-consent.service";

@Component({
  selector: 'ac-recaptcha-consent',
  standalone: true,
  imports: [CommonModule, ColibriModule, TranslocoPipe],
  templateUrl: './recaptcha-consent.component.html',
  styleUrl: './recaptcha-consent.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RecaptchaConsentComponent implements OnInit {
  @Output() accepted = new EventEmitter<boolean>();
  protected readonly environment = environment;
  #consentService = inject(RecaptchaConsentService);

  ngOnInit(): void {
    if(this.#consentService.hasGiveConsent()){
      this.accepted.emit(true)
    }
  }

  giveConsent() {
    this.#consentService.giveConsent();
    this.accepted.emit(true);
  }
}
